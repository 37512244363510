import api from "../axios";

import { ListItemResponse } from '../../utils';

// Components
import { Session } from '../../layouts/AuthenticatedLayout/AuthenticatedLayout';

// Interfaces

export const createLead = async (name: string, phone: string, email: string, product: number, company: string, channel: string, notes: string, collaborator: string  ) => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
    if(session !== null){
      const sessionBody: Session = JSON.parse(session);
      token = sessionBody.accessToken;
    }
    try {
      const body = {
        contact: {
            name: name,
            phone: phone,
            ...email && { email: email }
        },
        deal: {
            product: product,
            company: parseInt(company),
            channel: Number(channel),
            ...notes && { notes: notes }
        },
        collaborator: parseInt(collaborator),
        source: 1
    }
      const response = await api.post("api/crm/createLead", body, {
        headers: {
          "x-access-token": token
        }
      });
      return { res: true, data: response.data };
    } catch (error: any) {
      // const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
      const errorMessage = error.response?.data?.message || "Ocorreu um erro ao cadastrar lead";
      return { res: false, data: '', message: errorMessage };
    }
  };

  export const createCampaignLead = async (
    name: string,
    cpf: string,
    campaignId: number,
    phoneNumber: string,
    userId: number,
    email: string,
    productId: number,
    company: number,
    channel: string,
    notes: string,
  ) => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
    if (session !== null) {
      const sessionBody: Session = JSON.parse(session);
      token = sessionBody.accessToken;
    }
    try {
      const body = {
        name,
        cpf,
        campaignId,
        phoneNumber,
        userId,
        ...email && { email: email },
        productId,
        company,
        channel,
        ...notes && { notes: notes },
        source: 1,
      };
  
      const response = await api.post('api/lead', body, {
        headers: {
          'x-access-token': token,
        },
      });
      return { res: true, data: response.data };
    } catch (error: any) {
      const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
      return { res: false, data: '', message: errorMessage };
    }
  };  
  
  export const getCollaboratorsList = async () => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
    if(session !== null){
      const sessionBody: Session = JSON.parse(session);
      token = sessionBody.accessToken;
    }
  
    try {
      const response = await api.get("api/crm/getCollaboratorsList", {
        headers: {
          "x-access-token": token
        }
      });
  
      const dataResponse: ListItemResponse[] = response.data;
  
      return { res: true, data: dataResponse };
    } catch (error) {
      return { res: false, data: [] };
    }
  };
  
  export const getChannelsList = async () => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
    if(session !== null){
      const sessionBody: Session = JSON.parse(session);
      token = sessionBody.accessToken;
    }
  
    try {
      const response = await api.get("/api/crm/getChannelsList", {
        headers: {
          "x-access-token": token
        }
      });
  
      const dataResponse: ListItemResponse[] = response.data;
  
      return { res: true, data: dataResponse };
    } catch (error) {
      return { res: false, data: [] };
    }
  };

  
export const getProductsList = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get("/api/product", {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getUserLeads = async (userId: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/user/${userId}/lead`, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const deleteCampaignLeads = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.delete(`api/lead/${id}`, {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};