import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import notFoundImage from "../../assets/images/404.png";
import { StyledButton } from './styles';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Container  sx={{ 
        textAlign: 'center', 
        pt: '2rem',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${notFoundImage})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
      <Typography variant="h1" color="#184981" sx={{fontSize: '10rem', fontWeight: 'bold'}}>
        404
      </Typography>
      <Typography variant="h1" sx={{fontSize: '2rem', fontWeight: 'bold'}}>
        Página Não  Encontrada
      </Typography>
      <Typography variant="subtitle1" component="h2" color="textSecondary" sx={{ mt: '2rem' }}>
        Desculpe, não encontramos a página que você está procurando.
      </Typography>
      <Box sx={{ mt: '2rem' }}>
        <StyledButton variant="contained" color="primary" onClick={() => navigate('/leads')}>
          Ir para página Inicial
        </StyledButton>
      </Box>
    </Container>
  );
};
