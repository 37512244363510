import { Theme } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { SxProps } from '@mui/system';
import { CustomAlert } from './styles';


interface CustomSnackbarProps {
    open: boolean;
    onClose?: () => void;
    message: string | null;
    severity?: "error" | "warning" | "info" | "success";
    sx?: SxProps<Theme>;

  }

export const CustomSnackbar = ({ open, onClose, message, severity = "error", sx }: CustomSnackbarProps) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    onClose={onClose}
    sx={sx}
    ClickAwayListenerProps={{ mouseEvent: false }}
  >
    <CustomAlert onClose={onClose} severity={severity}>
      {message}
    </CustomAlert>
  </Snackbar>
);

