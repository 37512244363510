import jwt_decode from "jwt-decode";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';

interface DecodedToken {
  id: number;
  exp: number;
  iat: number;
}

export const useAuthRedirect = () => {
  const navigate = useNavigate();

  const isUserAuthenticated = () => {
    const session = sessionStorage.getItem('iv-crm');
    if (session !== null && session !== "\"\"") {
      const token = JSON.parse(session);
      const decoded_token: DecodedToken = jwt_decode(token.accessToken);
      const currentDate = new Date();
      return decoded_token.exp * 1000 > currentDate.getTime();
    }
    return false;
  };

  useEffect(() => {
    if (isUserAuthenticated()) {
      navigate('/inicio');
    }
  }, [navigate]);
};
