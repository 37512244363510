import { IconButton, IconButtonProps as MuiIconButtonProps } from "@mui/material";
import { styled } from "@mui/system";

  interface IconButtonProps extends MuiIconButtonProps {
    open?: boolean;
  }


export const Root = styled('div')(({ theme }) => ({
    display: "flex"
}));

export const MainContent = styled('main')<({ open: boolean })>(({ theme, open }) => ({
  minHeight: '100vh',  
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,

  [`@media (min-width:${theme.breakpoints.values.lg}px)`]: {
    marginLeft: '19.75rem',
  },
  
}));


export const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })<IconButtonProps>(({ theme, open }) => ({
    marginRight: theme.spacing(2),
    ...(open && {
        display: "none"
    })
}));