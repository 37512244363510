import { Box, Container, CssBaseline, Grid, useMediaQuery } from '@mui/material';
import bannerDefault from "../../assets/images/banner_3.png";
import logo from "../../assets/images/logo_sempre.png";
import { useAuthRedirect } from '../../hooks';
import { Banner, BannerColumn, FormColumn } from './styles';





export const GuestLayout = ({children, banner = bannerDefault} : {children: any, banner?: string}) => {

    useAuthRedirect();
  
    const isScreenWideEnough = useMediaQuery('(min-width: 992px)');

    return (
        <Container component="main" disableGutters maxWidth={false} sx={{overflowY: 'auto'}}>
            <CssBaseline />
            <Grid container sx={{ minHeight: '100vh' }}>
                
                {isScreenWideEnough && (
                <BannerColumn item xs={6}>
                    <Banner bgImage={banner}/>
                </BannerColumn>
                )}

                <FormColumn container xs={12} md={isScreenWideEnough ? 6 : 12}>
                    <Box sx={{  display: "flex", flexDirection: "column", alignItems: "center",}}>
                        <img alt="logo" src={logo} style ={{ width: "175px", marginTop: '1rem'}}></img>    
                        {children}
                    </Box>
                </FormColumn>

            </Grid>
        </Container>
    );
}
