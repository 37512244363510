import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, LinearProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from "yup";
import { updatePassword } from '../../../api';
import { CustomProgress } from '../../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import { StyledButton, StyledDivider, StyledForm } from './styles';



export const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();



  const resetPasswordValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Senha é obrigatória")
      .min(8, "Senha deve ter pelo menos 8 caracteres"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], "As senhas devem corresponder")
      .required("Confirmação de senha é obrigatória"),
    });

    const resetPasswordFormik = useFormik({
      initialValues: {
        password: '',
        confirmPassword: '',
      },
      validationSchema: resetPasswordValidationSchema,
      validateOnBlur: false,
      onSubmit: async (values: { password: string; confirmPassword: string; }) => {
        setIsLoading(true);
        try {
          const token = searchParams.get("token") || '';
          const response = await updatePassword(token, values.password);
          if (response.res) {
            setAlertMessage({message: response.message ? response.message : "Senha atualizada com sucesso!", severity: 'success'});
            setShowAlert(true); 
            setTimeout(() => {
              navigate('/');
            }, 3000);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true); 
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar atualizar a senha', severity: 'error'});
          setShowAlert(true); 
        }
      },
    });
    
  
  return (
    <Box>
      {showAlert && (
            <CustomSnackbar
            open={showAlert}
            message={alertMessage?.message || 'Ocorreu um erro'}
            severity={alertMessage?.severity}
          />
        )}

        {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <CustomProgress />
            </Box>
        ) : (

        <>
          <Box sx={{ pt: '2rem', px: '1rem', maxWidth: '480px', textAlign: 'left' }}>
            <Typography variant="h5" component="h1" fontWeight={'bold'} color={'#184981'}>Redefinir Senha</Typography>
            <Typography variant="subtitle1" component="h2">Insira sua nova senha abaixo</Typography>
          </Box>
          <StyledForm onSubmit={resetPasswordFormik.handleSubmit}>

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="newPassword"
                label="Nova Senha"
                name="password"
                type={showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                autoFocus
                value={resetPasswordFormik.values.password}
                onChange={resetPasswordFormik.handleChange}
                error={resetPasswordFormik.touched.password && Boolean(resetPasswordFormik.errors.password)}
                helperText={resetPasswordFormik.touched.password && resetPasswordFormik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                label="Confirmar Nova Senha"
                name="confirmPassword"
                type={showConfirmPassword ? 'text' : 'password'}
                autoComplete="new-password"
                value={resetPasswordFormik.values.confirmPassword}
                onChange={resetPasswordFormik.handleChange}
                error={resetPasswordFormik.touched.confirmPassword && Boolean(resetPasswordFormik.errors.confirmPassword)}
                helperText={resetPasswordFormik.touched.confirmPassword && resetPasswordFormik.errors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }} />
              <StyledDivider />

              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={showAlert}
              >
                Confirmar
              </StyledButton>

              {resetPasswordFormik.isSubmitting && <LinearProgress />}
          </StyledForm>
        </>
        )}
    </Box>
  );
};
