import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PeopleIcon from '@mui/icons-material/People';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomCard } from '../../components/CustomCard/CustomCard';

function checkPermission(permission: string) {
  const sessionObj = sessionStorage.getItem("iv-crm");
  if (sessionObj !== null && sessionObj !== '""') {
    const session = JSON.parse(sessionObj);
    return session.roles.includes("ROLE_" + permission);
  }
  return false;
}

export const Home = () => {
  const navigate = useNavigate();

  const isAdmin = checkPermission("ADMIN");

  const items = [
    {
      title: 'Cadastro de Leads',
      subtitle: 'Cadastre leads e ajude a empresa a crescer.',
      icon: <GroupsIcon />,
      path: '/leads',
      avatarColor: 'rgb(96, 160, 175)',
    },
    {
      title: 'Indique Sempre',
      subtitle: 'Participe, indique clientes e ganhe prêmios.',
      icon: <LocalActivityIcon />,
      path: '/indique-sempre',
      avatarColor: 'rgb(96, 160, 175)',
    },
    {
      title: 'Campanhas',
      subtitle: 'Crie e gerencie campanhas de indicação.',
      icon: <CampaignIcon />,
      path: '/campanhas',
      permission: 'ADMIN',
      avatarColor: '#184981',
    },
    {
      title: 'Usuários',
      subtitle: 'Administre usuários e organize sua equipe.',
      icon: <PeopleIcon />,
      path: '/usuarios',
      permission: 'ADMIN',
      avatarColor: '#184981',
    },
  ];

  return (
    <Grid container spacing={1}>
      {items.map((item, index) => (
        (!item.permission || (item.permission && isAdmin)) && (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <CustomCard 
              title={item.title} 
              // subtitle={item.subtitle} 
              icon={item.icon} 
              onClick={() => navigate(item.path)}
              avatarColor={item.avatarColor}
              sx={{maxHeight: '200px'}}
            >
              <Box 
              sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: '1', px: '3rem', pb: '2rem'}}
              >
                <Typography variant="body2" color="textSecondary" textAlign="left">{item.subtitle}</Typography>
              </Box>
            </CustomCard>
          </Grid>
        )
      ))}
    </Grid>
    
  );
}

