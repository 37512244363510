import { Groups } from '@mui/icons-material';
import { Autocomplete, Box, FormControl, InputLabel, OutlinedInput, Select, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { createLead, getChannelsList, getCollaboratorsList } from '../../api';
import { CustomCard } from '../../components/CustomCard/CustomCard';
import { CustomProgress } from '../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../components/CustomSnackbar/CustomSnackbar';
import PhoneMask from '../../components/Masks/PhoneMask';
import { CompanyOptions, ListItemResponse, getCompaniesList, getProductIdFromCompany } from '../../utils';
import { StyledButton, StyledCard, StyledForm, StyledTextField } from './styles';

export interface LeadRequest {
    contact: {
        name: string;
        phone: string;
        email?: string;
    }
    deal: {
        product: number;
        company: number;
        channel: number;
        notes?: string;
    }
    collaborator: number;
    source: number;
}

export default function Leads() {

    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();  


    const [product, setProduct] = useState<string>('');
    const [companiesList, setCompaniesList] = useState<CompanyOptions>({});
    const [collaboratorsList, setCollaboratorsList] = useState<ListItemResponse[] | []>([]);
    const [channelsList, setChannelsList] = useState<ListItemResponse[] | []>([]);


    const validationSchema = yup.object().shape({
        product: yup
        .string()
        .required('Produto de interesse é obrigatório'),
        company: yup
        .string()
        .required('Empresa de destino é obrigatório'),
        channel: yup
        .string()
        .required('Canal de contato é obrigatório'),
        clientName: yup
        .string()
        .required('Nome do cliente é obrigatório'),
        clientPhone: yup
        .string()
        .required('Telefone do cliente é obrigatório'),
        collaborator: yup
        .string().required('Colaborador é obrigatório'),
    });
    
    const formik = useFormik({
        initialValues: {
            product: '',
            company: '',
            channel: '',
            clientName: '',
            clientPhone: '',
            clientEmail: '',
            collaborator: '',
            notes: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const productId = await getProductIdFromCompany(values.product, values.company);
                const response = await createLead(values.clientName, values.clientPhone, values.clientEmail, productId, values.company, values.channel, values.notes, values.collaborator);
                if (response.res === true) {
                    setAlertMessage({message: response.message ? response.message : "Lead cadastrado com sucesso!", severity: 'success'});
                    setShowAlert(true); 

                    formik.resetForm();
                } else {
                    setAlertMessage({message: response.message ? response.message: "Erro ao cadastrar lead. Verifique se os campos são válidos.", severity: 'error'});
                    setShowAlert(true);
                }
            } catch (error: any) {
                console.error('Ocorreu um erro:', error);

            } finally {
                setIsLoading(false);
            }
        },
      });

    const sendCollaboratorsListRequest = async () => {
        let collaboratorListRequest = await getCollaboratorsList();
        if (collaboratorListRequest.res) {
            setCollaboratorsList(collaboratorListRequest.data);
        }
    }

    const sendChannelsListRequest = async () => {
        let channelsListRequest = await getChannelsList();
        if (channelsListRequest.res) {
            setChannelsList(channelsListRequest.data);
        }
    }
       

    useEffect(() => {
        let companies = getCompaniesList(product);
        setCompaniesList(companies);
    }, [product])

    useEffect(() => {
        sendCollaboratorsListRequest();
    }, []);

    useEffect(() => {
        sendChannelsListRequest();
    }, []);

    return (
        <Box>
            {showAlert && (
                <CustomSnackbar
                open={showAlert}
                onClose={() => setShowAlert(false)}
                message={alertMessage?.message || 'Ocorreu um erro'}
                severity={alertMessage?.severity}
                />
            )}

        {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <CustomProgress />
            </Box>
        ) : (
            <CustomCard title="Leads" subtitle="Cadastro de leads" icon={<Groups />}>
                <StyledCard>
                    <StyledForm onSubmit={formik.handleSubmit} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Produto de interesse *</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        labelId="select-product-label"
                                        id="select-product"
                                        name="product"
                                        label="Produto de interesse"
                                        value={formik.values.product}
                                        onChange={event => {
                                            formik.handleChange(event);
                                            setProduct(event.target.value);
                                        }}
                                        error={formik.touched.product && Boolean(formik.errors.product)}

                                    >
                                        <option aria-label="None" value="" />
                                        <optgroup label="Memorial Vila">
                                            <option value="prever_master">Prever Master</option>
                                            <option value="prever_prime">Prever Prime</option>
                                            <option value="corpos_imediato">Cremação de Corpo Imediato</option>
                                            <option value="corpos_futuro">Cremação de Corpo Futuro</option>
                                            <option value="restos_imediato">Cremação de Restos Imediato</option>
                                            <option value="restos_futuro">Cremação de Restos Futuro</option>
                                            <option value="modulo">Módulo Individual</option>
                                            <option value="jazigo_2">Jazigo de 2 gavetas</option>
                                            <option value="jazigo_3">Jazigo de 3 gavetas</option>
                                        </optgroup>
                                        <optgroup label="Plano Sempre">
                                            <option value="pleno">Sempre Pleno</option>
                                            <option value="mais">Sempre Mais</option>
                                            <option value="melhor">Sempre Melhor</option>
                                            <option value="unico">Aditivo Único</option>
                                        </optgroup>
                                        <optgroup label="Vila Pet">
                                            <option value="pet_imediato">Cremação Pet Imediato</option>
                                            <option value="pet_futuro">Cremação Pet Futuro</option>
                                        </optgroup>
                                    </Select>
                                    {formik.touched.product && formik.errors.product && (
                                        <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                            {formik.errors.product}
                                        </Typography>
                                    )}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Empresa de destino *</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        labelId="select-company-label"
                                        id="select-company"
                                        name="company"
                                        label="Empresa de destino"
                                        value={formik.values.company}
                                        onChange={formik.handleChange}
                                        disabled={product === ''}
                                        error={formik.touched.company && Boolean(formik.errors.company)}
                                        input={
                                            <OutlinedInput
                                                label="Unidade de destino"
                                                sx={{
                                                    backgroundColor: product === '' ? 'rgba(0, 0, 0, 0.09)' : 'transparent',
                                                    borderColor: formik.touched.company && Boolean(formik.errors.company) ? 'red' : undefined,
                                                }}
                                            />
                                        }
                                    >
                                        <option aria-label="None" value="" />
                                        {Object.keys(companiesList).map(function (key, index) {
                                            return (
                                                <option value={companiesList[key]}>{key}</option>
                                            )
                                        })}
                                    </Select>
                                    {formik.touched.company && formik.errors.company && (
                                        <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                        {formik.errors.company}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl variant="outlined" sx={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Canal de contato *</InputLabel>
                                    <Select
                                        native
                                        fullWidth
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label="Canal de contato"
                                        name="channel"
                                        value={formik.values.channel}
                                        onChange={formik.handleChange}
                                        error={formik.touched.channel && Boolean(formik.errors.channel)}

                                    >
                                        <option aria-label="None" value="" />
                                        {channelsList.length > 0 && (channelsList.map((c, index) => {
                                            return (
                                                <option value={c.id} key={index}>{c.label}</option>
                                            )
                                        }))}
                                    </Select>
                                    {formik.touched.channel && formik.errors.channel && (
                                        <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                        {formik.errors.channel}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <StyledTextField
                                    autoComplete="cname"
                                    name="clientName"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="clientName"
                                    label="Nome do cliente"
                                    autoFocus
                                    value={formik.values.clientName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                                    helperText={formik.touched.clientName && formik.errors.clientName}
                                
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <StyledTextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="clientPhone"
                                    label="Telefone do cliente"
                                    name="clientPhone"
                                    autoComplete="phone"
                                    value={formik.values.clientPhone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.clientPhone && Boolean(formik.errors.clientPhone)}
                                    helperText={formik.touched.clientPhone && formik.errors.clientPhone}
                                    InputProps={{
                                        inputComponent: PhoneMask as any,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="clientEmail"
                                    label="Email do cliente"
                                    name="clientEmail"
                                    autoComplete="email"
                                    value={formik.values.clientEmail}
                                    onChange={formik.handleChange}
                                    error={formik.touched.clientEmail && Boolean(formik.errors.clientEmail)}
                                    helperText={formik.touched.clientEmail && formik.errors.clientEmail}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Autocomplete
                                    id="collaborator"
                                    options={collaboratorsList}
                                    getOptionLabel={(option) => option.label}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue('collaborator', newValue ? newValue.id : '');
                                    }}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params} 
                                            label="Colaborador *" 
                                            variant="outlined" 
                                            error={formik.touched.collaborator && Boolean(formik.errors.collaborator)}
                                        />
                                    }
                                />
                                    {formik.touched.collaborator && formik.errors.collaborator && (
                                        <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                        {formik.errors.collaborator}
                                        </Typography>
                                    )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    fullWidth
                                    name="notes"
                                    label="Observações"
                                    type="notes"
                                    id="notes"
                                    value={formik.values.notes}
                                    onChange={formik.handleChange}
                                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                                    helperText={formik.touched.notes && formik.errors.notes}
                                    inputProps={{ maxLength: 2000 }}
                                />
                            </Grid>

                        </Grid>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={12} sm={4}>
                                <StyledButton
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => formik.resetForm()}
                                >
                                    Limpar
                                </StyledButton>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <StyledButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={showAlert}
                                >
                                    Cadastrar
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </StyledForm>
                </StyledCard>
            </CustomCard>
            )}
        </Box>
        
    );
}