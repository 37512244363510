import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import React, { ReactNode } from 'react';
import { StyledButton, StyledDialog } from './styles';



interface CustomDialogProps {
    open: boolean;
    onClose: () => void;
    title: string;
    children?: ReactNode;
    defaultValues?: {
        name?: string;
        expiresAt?: string;
    };
    onSubmit?: () => void;
    cancelButtonText: string;
    confirmButtonText: string;
    maxWidth?: false | Breakpoint | undefined;
    disabled?: boolean;
}


export const CustomDialog: React.FC<CustomDialogProps> = ({ open, onClose, title, children, onSubmit, cancelButtonText, confirmButtonText, maxWidth, disabled }) => {

    return (
        <StyledDialog 
        open={open} 
        onClose={onClose} 
        fullScreen={false}
        maxWidth={maxWidth}
        
>
            <DialogTitle>
                    {title}
                    <IconButton 
                        edge="end" 
                        onClick={onClose} 
                        aria-label="close"
                        sx={{
                            position: 'absolute',
                            right: 20,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                          }}
                    >
                        <CloseIcon />
                    </IconButton>
            </DialogTitle>
            
            <DialogContent dividers>
                <DialogContent sx={{px: 0 }}>
                    {children}
                </DialogContent>
            </DialogContent>
            
            <DialogActions>
                <Grid container columnSpacing={2} justifyContent="space-between" wrap='wrap-reverse'>
                    <Grid item xs={12} sm={4}>
                        <StyledButton
                            type="button"
                            fullWidth
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                        >
                            {cancelButtonText}
                        </StyledButton>
                    </Grid>
                    <Grid item xs={12} sm={4}>

                        <StyledButton
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                            disabled={disabled}
                        >
                            {confirmButtonText}
                        </StyledButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </StyledDialog>
    );
}
