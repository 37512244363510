import { Alert, Button, Divider, Link } from '@mui/material';
import { styled } from '@mui/system';
import CodeInput from 'react-code-input';


export const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
}));

export const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(0),
  padding: theme.spacing(0, 2, 2 , 2),
  maxWidth: '480px',
  width: '100%'

}));

export const LinkWrapper = styled('div')({
  textAlign: 'right',    
});

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  margin: theme.spacing(0, 0, 0, 1)
}));

export const StyledOtpInput = styled(CodeInput)(({ theme }) => ({
  '& > input': {
    fontFamily: 'monospace',
    borderRadius: '6px',
    border: '1px solid lightgrey',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 10px 0px',
    margin: '4px',
    paddingLeft: '8px',
    width: '36px !important',
    height: '42px',
    fontSize: '32px',
    boxSizing: 'border-box',
  },
}));

export const StyledDivider = styled(Divider)({
  flexShrink: 0,
  borderTop: '0px solid rgba(0, 0, 0, 0.12)',
  borderRight: '0px solid rgba(0, 0, 0, 0.12)',
  borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: 'transparent',
  height: '0.0625rem',
  marginTop: '2rem',
  marginBottom: '1rem',
  borderBottom: 'none',
  opacity: 0.25,
  backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
});
