export interface CompanyOptions { [key: string]: string; }

export interface CompanyInterface { [key:string]: CompanyOptions; }


export const getCompaniesList = (input_product: string) => {
    const helper: CompanyInterface = {
        prever_master: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        prever_prime: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        corpos_imediato: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        corpos_futuro: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        restos_imediato: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        restos_futuro: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        modulo: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        jazigo_2: {
            'Cemitério e Crematório Vila Memorial': '341247',
            'Cemitério Vila Memorial Caicó': '341244',
            'Cemitério Vila Memorial SFI': '341246',
        },
        jazigo_3: {
            'Cemitério e Crematório Vila Memorial': '341247',
        },
        jazigo_2_natal:{
            'Sempre Safra': '341247',
        },
        jazigo_3_natal:{
        'Sempre Safra': '341247',
        },
        jazigo_2_sfi:{
        'Sempre SFI': '341246',
        },
        jazigo_3_sfi:{
        'Sempre SFI': '341246',
        },
        jazigo_vip_caico:{
        'Sempre Caicó': '341244',

        },
        jazigo_standard_caico:{
        'Sempre Caicó': '341244',
        },
        pet_imediato: {
            'Crematório Vila Pet': '341247',
        },
        pet_futuro: {
            'Crematório Vila Pet': '341247',
         },
        pleno: {
            'Sempre Caicó': '341244',
            'Sempre Catolé do Rocha': '341245',
            'Sempre João Pessoa': '341243',
            'Sempre Safra': '341247',
            'Sempre SFI': '341246',

        },
        mais: {
            'Sempre Caicó': '341244',
            'Sempre Catolé do Rocha': '341245',
            'Sempre João Pessoa': '341243',
            'Sempre Safra': '341247',
            'Sempre SFI': '341246',
        },
        melhor: {
            'Sempre Caicó': '341244',
            'Sempre Catolé do Rocha': '341245',
            'Sempre João Pessoa': '341243',
            'Sempre Safra': '341247',
            'Sempre SFI': '341246',
        },
        unico: {
            'Sempre Caicó': '341244',
            'Sempre Safra': '341247',
            'Sempre SFI': '341246',
        },
        sempre_do_seu_jeito:{
            'Sempre Caicó': '341244',
            'Sempre Catolé do Rocha': '341245',
            'Sempre João Pessoa': '341243',
            'Sempre Safra': '341247',
            'Sempre SFI': '341246',
        }
    };

    const options: CompanyOptions = helper[input_product] || {};

    return options;
}