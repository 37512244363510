import { StyledBadge } from './styles';


interface CustomBadgeProps {
    color: 'error' | 'warning' | 'info' | 'success' ;
    message: string | null;
  }

export const CustomBadge = ({ message, color }: CustomBadgeProps) => (
    <StyledBadge color={color}>
        {message}
    </StyledBadge>
);

