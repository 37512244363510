import { Alert, Button, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';


export const StyledAlert = styled(Alert)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

export const StyledForm = styled('form')(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  maxWidth: '480px',
  width: '100%'
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0, 1),
  padding: theme.spacing(1.5),
}));

export const LinkWrapper = styled('div')({
  textAlign: 'right',    
});

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  margin: theme.spacing(0, 0, 0, 1)
}));

export const StyledDivider = styled(Divider)({
  flexShrink: 0,
  borderTop: '0px solid rgba(0, 0, 0, 0.12)',
  borderRight: '0px solid rgba(0, 0, 0, 0.12)',
  borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
  backgroundColor: 'transparent',
  height: '0.0625rem',
  margin: '1rem 0px',
  borderBottom: 'none',
  opacity: 0.25,
  backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
});
