import api from "../axios";

export const signIn = async (user: string, password: string) => {
    try {
      const body = {email: user, password: password}
      const response = await api.post("api/auth/signin", body);
      return { res: true, data: response.data };
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Erro desconhecido';
      return { res: false, data: '', message: errorMessage };
    }
  };

  
export const signUp = async (username: string, password: string, email: string, name: string, dateOfBirth: string, phoneNumber: string, recno: string, registration: string, company: string, branch: string, cpf: string) => {
  try {
    const body = { username: username, password: password, email: email, name: name, dateOfBirth: dateOfBirth, phoneNumber: phoneNumber, recno: recno, registration: registration, company: company, branch: branch, cpf: cpf};
    const response = await api.post("api/user", body);
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const verifyUser = async (cpf: string, dateOfBirth: string) => {
    try {
      const body = {cpf: cpf, dateOfBirth: dateOfBirth}
      const response = await api.post("api/user/verify-if-exist", body);
      return { res: true, data: response.data };
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Erro desconhecido';
      return { res: false, data: '', message: errorMessage };
    }
  };

export const sendVerificationCodeToEmail = async (email: string, name: string) => {
  try {
    const body = { email, name };
    const response = await api.post("api/user/email/verification-code", body);
    return { res: true, data: response.data };
  } catch (error) {
    return { res: false, data: '' };
  }
};
  
export const sendVerificationCodeToPhone = async (phoneNumber: string) => {
    try {
      const body = { phoneNumber };
      const response = await api.post("api/user/sms/verification-code", body);
      return { res: true, data: response.data };
    } catch (error) {
      return { res: false, data: '' };
    }
  };

export const sendRestorePasswordEmail = async (email: string) => {
  try {
    const response = await api.post("api/auth/send-restore-password-email", { email });
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const updatePassword = async (token: string, newPassword: string) => {
  try {
    const body = { token, password: newPassword };
    const response = await api.patch("api/auth/update-password/", body);
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};




