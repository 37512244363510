import { Alert } from "@mui/material";
import { styled } from "@mui/system";

export const CustomAlert = styled(Alert)(({ theme, severity }) => {
    let backgroundColor, contrastTextColor;
  
    switch (severity) {
      case 'error':
        backgroundColor = theme.palette.error.light;
        contrastTextColor = theme.palette.error.contrastText;
        break;
      case 'warning':
        backgroundColor = theme.palette.warning.light;
        contrastTextColor = theme.palette.warning.contrastText;
        break;
      case 'info':
        backgroundColor = theme.palette.info.light;
        contrastTextColor = theme.palette.info.contrastText;
        break;
      case 'success':
        backgroundColor = theme.palette.success.light;
        contrastTextColor = theme.palette.success.contrastText;
        break;
      default:
        backgroundColor = theme.palette.background.default;
        contrastTextColor = theme.palette.text.primary;
    }
  
    return {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: backgroundColor,
      color: contrastTextColor,
      '& .MuiAlert-icon': {
        color: contrastTextColor,
      },
    };
  });
  