import { AppBar, AppBarProps as MuiAppBarProps } from "@mui/material";
import { styled } from "@mui/system";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

export const drawerWidth = 300;

export const StyledAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(({ theme, open }) => ({
  position: "sticky",
  top: "1.5rem",
  borderRadius: '12px',
  width:  '100%',
  }));