import { Box, Container, CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Copyright from '../../components/Copyright/Copyright';
import { CustomAppBar } from '../../components/CustomAppBar/CustomAppBar';
import { TLink } from '../../components/CustomBreadcrumbs/CustomBreadcrumbs';
import CustomDrawer from '../../components/CustomDrawer/CustomDrawer';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs/HeaderBreadcrumbs';
import { useDrawer } from '../../contexts/DrawerContext';
import { MainContent } from './styles';


export interface Session {
  id: number;
  username: string;
  email: string;
  roles: Array<string>;
  accessToken: string;
}

interface DecodedToken {
  id: number;
  exp: number;
  iat: number;
}

interface AuthenticatedLayoutProps {
  children: React.ReactNode;
  permission?: string;
  title: string;
  breadcrumbs?: TLink[];
}

export const AuthenticatedLayout = ({ children, permission, title, breadcrumbs }: AuthenticatedLayoutProps) => {
  const navigate = useNavigate();

  const theme = useTheme();
  const { open } = useDrawer();
  const isLargeEnough = useMediaQuery(theme.breakpoints.up('lg'));
  const [isValidToken, setIsValidToken] = useState<boolean>(false);


  useEffect(() => {

  const validateToken = () => {
    const session = sessionStorage.getItem('iv-crm');
    if (session !== null && session !== "\"\"") {
      const token: Session = JSON.parse(session);
      const decoded_token: DecodedToken = jwt_decode(token.accessToken);
      const currentDate = new Date();
      
      if (decoded_token.exp * 1000 > currentDate.getTime()) {
        if (permission === undefined) {
          return true;
        }
        const hasPermission = checkPermissions(token, permission);
        if (hasPermission) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

    const tokenValid = validateToken();
    setIsValidToken(tokenValid);
    if (!tokenValid) {
      void navigate('/');
    }
  }, [navigate, permission]);

  const checkPermissions = (session: Session, permission: string | undefined) => {
    const permissionsArray = session.roles;
    if (permissionsArray.includes("ROLE_" + permission)) {
      return true;
    } else {
      return false;
    }
  };

  // useEffect(() => {
  //   if (!validateToken()) {
  //     void navigate('/');
  //   }
  // }, []);

return (
  <Container component="main" disableGutters maxWidth={false} sx={{ height: '100vh' }}>
        <CssBaseline />
        {isValidToken && (
          <Box >
            <CustomDrawer />
            <MainContent open={isLargeEnough && open}  >
              <CustomAppBar title={title} />
              <Container sx={{
                pt:'2rem', 
                px: '0px!important',
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between', 
                flexGrow: '1', 
                // maxWidth: theme.breakpoints.values.xl ,
                }}>
                <Box>
                  {breadcrumbs && <HeaderBreadcrumbs heading={title} links={breadcrumbs} />}
                  {children}
                </Box>
                <Copyright />
              </Container>
            </MainContent>
          </Box>
        )}
  </Container>
);
};
