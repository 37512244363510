import { Leaderboard } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import ErrorIcon from '@mui/icons-material/Error';
import MedalIcon from '@mui/icons-material/MilitaryTech';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Button, CardContent, Grid, List, ListItem, ListItemText, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createCampaignFinalResult, getCampaignDetails, getCampaignFinalResultDetails, getCampaignSnapshotResult } from '../../../api';
import { CustomBadge } from '../../../components/CustomBadge/CustomBadge';
import { CustomCard } from '../../../components/CustomCard/CustomCard';
import { CustomDialog } from '../../../components/CustomDialog/CustomDialog';
import { CustomProgress } from '../../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import { formatDate, getCampaignStatusColor } from '../../../utils';
import { BronzeIcon, BronzeRow, GoldIcon, GoldRow, SilverIcon, SilverRow, StyledCard, StyledTable, StyledTableContainer, StyledTableHeadCell } from './styles';


interface Lead {
    id: number;
    name: string;
    productId: number;
    status: string;
    planValue: string;
  }

interface CampaignSnapshotResult {
  id: number;
  resultId: number;
  name: string;
  userId: number;
  ticketAvg: number;
  leadsConv: number;
  leadsTotal: number;
  position: number;
  createdAt: string;
  updatedAt: string;
  leads: Lead[];
}

  interface CampaignRule {
    id: number;
    name: string;
    campaign_rules: {
      id: number;
      ruleId: number;
      campaignId: number;
      position: number;
      createdAt: string;
      updatedAt: string;
      rule_id: number;
      campaign_id: number;
    };
  }

  interface Campaign {
    campaign: {
      id: number;
      name: string;
      description: string;
      targetIndication: number;
      maxWinners: number;
      startsAt: string;
      expiresAt: string;
      createdAt: string;
      updatedAt: string;
      status: string;
      rules: CampaignRule[];
    };
    participants: number;
  }
  
  interface CampaignInfo {
    targetIndication: number;
    maxWinners: number;
    participants: number;
    rules: CampaignRule[];
}
  

export const CampaignSnapshotResults = () => {
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);

    const [snapshotResults, setSnapshotResults] = useState<CampaignSnapshotResult[]>([]);
    const [campaignDetails, setCampaignDetails] = useState<Campaign>();
    const [campaignInfo, setCampaignInfo] = useState<CampaignInfo>();

    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();  


    const { campaignId, resultId } = useParams();
    
    useEffect(() => {
        const fetchPartialResults = async () => {
          setIsLoading(true);
          try {
            const response = await getCampaignSnapshotResult(Number(resultId),);
            if (response.res) {
              setSnapshotResults(response.data);
            } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true);
            }
          } catch (error) {
                setAlertMessage({message: 'Erro ao carregar resultado da campanha', severity: 'error'});
                setShowAlert(true);
          } finally {
            setIsLoading(false);
          }
        };

        const fetchCampaignDetails = async () => {
            setIsLoading(true);
            try {
              const response = await getCampaignDetails(Number(campaignId));
              if (response.res) {
                setCampaignDetails(response.data);
              } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
              }
            } catch (error) {
                setAlertMessage({message: 'Erro ao carregar detalhes da campanha', severity: 'error'});
                setShowAlert(true);
            } finally {
              setIsLoading(false);
            }
        };

        const fetchCampaignInfo = async () => {
            setIsLoading(true);
            try {
              const response = await getCampaignFinalResultDetails(Number(resultId));
              if (response.res) {
                setCampaignInfo(response.data);
              } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
              }
            } catch (error) {
                setAlertMessage({message: 'Erro ao carregar informações da campanha', severity: 'error'});
                setShowAlert(true);
            } finally {
              setIsLoading(false);
            }
        };      

        fetchPartialResults();
        fetchCampaignDetails();
        fetchCampaignInfo();
    }, [campaignId, resultId]);


    const handleCreateCampaignFinalResult = async () => {
      try {
        const response = await createCampaignFinalResult(Number(resultId));
        if (response.res) {
          setOpenDialog(false);
          setAlertMessage({message: response.message ? response.message : "Resultado da campanha divulgado com sucesso", severity: 'success'});
          setShowAlert(true); 
          setTimeout(() => {
            navigate('/campanhas');
          }, 3000);
        } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
        }
      } catch (error) {
          setAlertMessage({message: 'Ocorreu um erro ao divulgar o resultado da campanha', severity: 'error'});
          setShowAlert(true); 
      } finally {
        setOpenDialog(false);
      }
    };  

    const handleCloseDialog = (setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
      setOpenDialog(false);
    };

  return (
    <Box>
      {showAlert && (
        <CustomSnackbar
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message={alertMessage?.message || 'Ocorreu um erro'}
          severity={alertMessage?.severity}
        />
      )}  

      {isLoading || campaignDetails === undefined || campaignInfo === undefined ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <CustomProgress />
          </Box>
      ) : (        
          <>
            {campaignDetails.campaign.status !== 'Finalizada' && (
              
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button 
                  variant="outlined"
                  onClick={() => setOpenDialog(true)}
                  startIcon={<PublishIcon />}
                >
                  Publicar Resultado Final
                </Button>
              </Box>
            )}
            
            <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">{campaignDetails.campaign.name}</Typography>
                              <Typography variant="body1" sx={{py: '1rem'}} color="textSecondary">{campaignDetails.campaign.description}</Typography>
                              <Grid container spacing={1} mt=".5rem">
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Início</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.startsAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Término</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.expiresAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4} 
                                    sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}
                                  >
                                      <CustomBadge color={getCampaignStatusColor(campaignDetails.campaign.status)} message={campaignDetails.campaign.status} />
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={12}>
                                      <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Critério de Desempate</Typography>
                                      <List>
                                          {campaignInfo.rules.map((rule, index) => (
                                              <ListItem key={rule.id}>
                                                  <ListItemText primary={`${index + 1}. ${rule.name}`} color="textSecondary"/>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </Grid>
                              </Grid>
                          </CardContent>

                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Total Participantes</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignInfo.participants}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Máx. de Vencedores</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignInfo.maxWinners}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Min. de Indicações</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignInfo.targetIndication}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  

              </Grid>

            <CustomCard title="Vencedores da Campanha" icon={<TrophyIcon />}>
              <StyledTableContainer>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>Posição</StyledTableHeadCell>
                                <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                <StyledTableHeadCell >Leads Cadastrados</StyledTableHeadCell>
                                <StyledTableHeadCell >Leads Convertidos</StyledTableHeadCell>
                                <StyledTableHeadCell >Ticket Médio</StyledTableHeadCell>
                                <StyledTableHeadCell></StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {snapshotResults.filter(person => person.leadsConv >= campaignInfo.targetIndication).slice(0, campaignInfo.maxWinners).map((person, index) => {

                                let CustomTableRow;
                                const maxWinners = campaignInfo.maxWinners;
                                const targetIndication = campaignInfo.targetIndication;
                                const position = index + 1;          

                              if (position <= maxWinners) {
                                if (index === 0) {
                                    CustomTableRow = GoldRow;
                                } else if (index === 1) {
                                    CustomTableRow = SilverRow;
                                } else if (index === 2) {
                                    CustomTableRow = BronzeRow;
                                } else {
                                    CustomTableRow = TableRow;
                                }
                            } else {
                                CustomTableRow = TableRow;
                            }

                                return (
                                    <React.Fragment key={person.userId}>
                                        <CustomTableRow sx={{ height: '70px' }}>
                                            <TableCell style={{ width: '15%' }}>
                                                <Box display="flex" alignItems="center">
                                                {position <= maxWinners && index < 3 && person.leadsConv >= targetIndication ? (
                                                        <React.Fragment>
                                                            {index === 0 && <GoldIcon as={TrophyIcon} />}
                                                            {index === 1 && <SilverIcon as={MedalIcon} />}
                                                            {index === 2 && <BronzeIcon as={MedalIcon} />}
                                                        </React.Fragment>
                                                    ) : (
                                                        <Box width={24} />
                                                    )}
                                                  <Box ml={2}>{ person.leadsConv >= targetIndication ? position + 'º' : '' }</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{person.name}</TableCell>
                                            <TableCell>{person.leadsTotal}</TableCell>
                                            <TableCell> {person.leadsConv}</TableCell>
                                            <TableCell>{person.ticketAvg ? `R$ ${person.ticketAvg.toFixed(2)}` : ''}</TableCell>
                                            <TableCell></TableCell>
                                        </CustomTableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </StyledTable>
              </StyledTableContainer>
            </CustomCard>
              
            <CustomCard title="Classificação Geral" icon={<Leaderboard />}>
              <StyledTableContainer>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>Posição</StyledTableHeadCell>
                                <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                <StyledTableHeadCell >Leads Cadastrados</StyledTableHeadCell>
                                <StyledTableHeadCell >Leads Convertidos</StyledTableHeadCell>
                                <StyledTableHeadCell >Ticket Médio</StyledTableHeadCell>
                                <StyledTableHeadCell></StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {snapshotResults.map((person, index) => {                              
                                let CustomTableRow;
                                const maxWinners = campaignInfo.maxWinners;
                                const targetIndication = campaignInfo.targetIndication;
                                const position = index + 1;

                              if (position <= maxWinners) {
                                if (index === 0) {
                                    CustomTableRow = GoldRow;
                                } else if (index === 1) {
                                    CustomTableRow = SilverRow;
                                } else if (index === 2) {
                                    CustomTableRow = BronzeRow;
                                } else {
                                    CustomTableRow = TableRow;
                                }
                            } else {
                                CustomTableRow = TableRow;
                            }

                                return (
                                    <React.Fragment key={person.userId}>
                                        <CustomTableRow sx={{ height: '70px' }}>
                                            <TableCell style={{ width: '10%' }}>
                                                <Box display="flex" alignItems="center">
                                                {position <= maxWinners && index < 3 && person.leadsConv >= targetIndication ? (
                                                        <React.Fragment>
                                                            {index === 0 && <GoldIcon as={TrophyIcon} />}
                                                            {index === 1 && <SilverIcon as={MedalIcon} />}
                                                            {index === 2 && <BronzeIcon as={MedalIcon} />}
                                                        </React.Fragment>
                                                    ) : (
                                                        <Box width={24} />
                                                    )}
                                                  <Box ml={2}>{ person.leadsConv >= targetIndication ? position + 'º' : '' }</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{person.name}</TableCell>
                                            <TableCell>{person.leadsTotal}</TableCell>
                                            <TableCell>
                                              <Box display="flex" alignItems="center">
                                                  {person.leadsConv}
                                                  {person.leadsConv >= targetIndication ? 
                                                      <Tooltip title="Elegível"><CheckCircleIcon sx={{color: 'success.light', ml: 1}} /></Tooltip> : 
                                                      <Tooltip title="Não Elegível"><ErrorIcon sx={{color: 'error.light', ml: 1}}/></Tooltip>
                                                  }
                                              </Box>
                                          </TableCell>
                                            <TableCell>{person.ticketAvg ? `R$ ${person.ticketAvg.toFixed(2)}` : ''}</TableCell>
                                            <TableCell></TableCell>
                                        </CustomTableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </StyledTable>
              </StyledTableContainer>
            </CustomCard>
              </>
          )}

          <CustomDialog
            open={openDialog}
            onClose={() => handleCloseDialog(setOpenDialog)}
            title="Atenção!"
            cancelButtonText='Cancelar'
            confirmButtonText='Publicar'
            onSubmit={handleCreateCampaignFinalResult}
            maxWidth={'xs'}
            >
          
          <Typography>
            Após publicar o resultado final, não será possível fazer novas publicações. Deseja continuar?
          </Typography>
          
        </CustomDialog>
    </Box>
  );
};