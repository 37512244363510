import { Box, Grid, LinearProgress, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { sendRestorePasswordEmail } from '../../../api';
import { StyledAlert, StyledButton, StyledDivider, StyledForm } from './styles';



export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [passwordResetRequested, setPasswordResetRequested] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean | undefined>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setLoading(true);
      setShowAlert(false);
      try {
        const response = await sendRestorePasswordEmail(values.email);
        if (response.res === true) {
          setPasswordResetRequested(true);
        } else {
          setError(response.message);
          setShowAlert(true);
        }
      } catch (error) {
        console.error('Erro ao tentar redefinir senha:', error);
        setError('Erro desconhecido');
        setShowAlert(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Box>
      {!passwordResetRequested ? (
        <StyledForm onSubmit={formik.handleSubmit} noValidate>
          <Box>
            <Typography variant="h5" component="h1" fontWeight={'bold'} color={'#184981'}>Esqueceu a senha?</Typography>
            <Typography variant="subtitle1" component="h2">Informe seu email para receber as instruções de recuperação de senha</Typography>
          </Box>

          {showAlert && (
            <StyledAlert severity="error" onClose={() => setShowAlert(false)}>
              {error}
            </StyledAlert>
          )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur} 
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <StyledDivider />

          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <StyledButton
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => navigate("/")}
              >
                Voltar
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={loading}
              >
                Redefinir Senha
              </StyledButton>
            </Grid>
          </Grid>

          {loading && <LinearProgress />}
        </StyledForm>
      ) : (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '448px', mt: '2rem', p: '1rem'}}>
          <Typography variant="subtitle1" component="h2">Enviamos um email com um link para a redefinição da senha.</Typography>
          <StyledButton
            variant="outlined"
            color="primary"
            onClick={() => navigate("/")}
          >
            Voltar para página inicial
          </StyledButton>
        </Box>
      )}
    </Box>
  );
  
};
