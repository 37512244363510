import { Campaign, Leaderboard, Person } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MedalIcon from '@mui/icons-material/MilitaryTech';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { Box, Button, CardContent, Grid, List, ListItem, ListItemText, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCampaignDetails, getCampaignPartialResult, getProductsList, getUserLeads } from '../../api';
import { deleteCampaignLeads } from '../../api/services/Leads';
import { CustomBadge } from '../../components/CustomBadge/CustomBadge';
import { CustomCard } from '../../components/CustomCard/CustomCard';
import { CustomDialog } from '../../components/CustomDialog/CustomDialog';
import { CustomProgress } from '../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../components/CustomSnackbar/CustomSnackbar';
import { formatDate, getCampaignStatusColor, getLeadStatusColor } from '../../utils';
import { BronzeIcon, BronzeRow, GoldIcon, GoldRow, SilverIcon, SilverRow, StyledCard, StyledTable, StyledTableContainer, StyledTableHeadCell } from './styles';

interface Lead {
    id: number;
    name: string;
    productId: number;
    status: string;
    planValue: string;
  }
interface CampaignPartialResult {
    user_id: number;
    username: string;
    cpf: string;
    name: string;
    campaign_id: number;
    leads_total: number;
    leads_conv: number;
    ticket_avg: number | null;
    leads: Lead[];
  }

  interface CampaignRule {
    id: number;
    name: string;
    campaign_rules: {
      id: number;
      ruleId: number;
      campaignId: number;
      position: number;
      createdAt: string;
      updatedAt: string;
      rule_id: number;
      campaign_id: number;
    };
  }

  interface Campaign {
    campaign: {
      id: number;
      name: string;
      description: string;
      targetIndication: number;
      maxWinners: number;
      startsAt: string;
      expiresAt: string;
      createdAt: string;
      updatedAt: string;
      status: string;
      rules: CampaignRule[];
    };
    participants: number;
  }
  
export const CCampaignPartialResults = () => {
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);

    const [openRows, setOpenRows] = React.useState<Record<number, boolean>>({});
    const [openRowsUser, setOpenRowsUser] = React.useState<Record<number, boolean>>({});
    const [partialResults, setPartialResults] = useState<CampaignPartialResult[]>([]);
    const [userPartialResults, setUserPartialResults] = useState<CampaignPartialResult[]>([]);
    const [campaignDetails, setCampaignDetails] = useState<Campaign>();
    const [products, setProducts] = useState<{ id: number; name: string }[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);    
    const [deleteLeadId, setDeleteLeadId] = useState<number | null>(null);
    const [userIndex, setUserIndex] = useState<number>();

    
    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>(); 

    const session = sessionStorage.getItem('iv-crm');
    const sessionData = session && JSON.parse(session);
    const userId = sessionData && sessionData.id;

    const { campaignId } = useParams();

    useEffect(() => {

    const fetchPartialResult = async () => {
        setIsLoading(true);
        try {
          const response = await getCampaignPartialResult(Number(campaignId));
          if (response.res) {
            setPartialResults(response.data);

            // Filtrar os resultados para incluir apenas aqueles que correspondem ao usuário logado
            const userResults = response.data.filter((result: CampaignPartialResult) => result.user_id === userId);
            setUserPartialResults(userResults);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar carregar resultado da campanha', severity: 'error'});
          setShowAlert(true);
        } finally {
          setIsLoading(false);
        }
      };     
     
      const fetchCampaignDetails = async () => {
        setIsLoading(true);
        try {
          const response = await getCampaignDetails(Number(campaignId));
          if (response.res) {
            setCampaignDetails(response.data);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar carregar detalhes da campanha', severity: 'error'});
          setShowAlert(true);
        } finally {
          setIsLoading(false);
        }
      };    

      const fetchProducts = async () => {
        try {
          setIsLoading(true);
          const response = await getProductsList();
          if (response.res) {
            setProducts(response.data);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar listar produtos', severity: 'error'});
          setShowAlert(true);
        } finally {
          setIsLoading(false);
        }
      };      
        fetchPartialResult();
        fetchCampaignDetails();
        fetchProducts();
      }, [campaignId, userId]);
      

      const getProductName = (productId: number) => {
        const product = products.find(p => p.id === productId);
        return product ? product.name : 'Produto Não Encontrado';
      };

      const handleShowLeads = async (index: number) => {
        const isOpen = !openRows[index];
        setOpenRows({ ...openRows, [index]: isOpen });
    
        if (isOpen) {
          if (!partialResults[index].leads) {
            try {
              const response = await getUserLeads(partialResults[index].user_id);
              if (response.res) {
                const filteredLeads = response.data.filter((lead: { campaign_id: number; }) => lead.campaign_id === Number(campaignId));

                const updatedResults = [...partialResults];
                updatedResults[index].leads = filteredLeads;
                setPartialResults(updatedResults);
              } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
              }
            } catch (error) {
              setAlertMessage({message: 'Erro ao tentar carregar leads dos usuários', severity: 'error'});
              setShowAlert(true);
            }
          }
        }
    };

      const handleShowLeadsUser = async (index: number) => {
        const isOpen = !openRowsUser[index];
        setOpenRowsUser({ ...openRowsUser, [index]: isOpen });
    
        if (isOpen) {
          if (!userPartialResults[index].leads) {
            try {
              const response = await getUserLeads(userPartialResults[index].user_id);
              if (response.res) {
                const filteredLeads = response.data.filter((lead: { campaign_id: number; }) => lead.campaign_id === Number(campaignId));

                const updatedResults = [...userPartialResults];
                updatedResults[index].leads = filteredLeads;
                setUserPartialResults(updatedResults);
              } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
              }
            } catch (error) {
              setAlertMessage({message: 'Erro ao tentar carregar leads dos usuários', severity: 'error'});
              setShowAlert(true);
            }
          }
        }
    };

    const handleDeleteLead = async (id: number) => {
        try {
          const response = await deleteCampaignLeads(id);
          if (response.res) {
            // Copiar a lista de resultados parciais
            const updatedResults = [...partialResults];
           // Encontrar o usuário com o lead que foi excluído
            const userResult = partialResults.find(userResult => {
                return userResult.leads?.some(lead => lead.id === id);
            });

            // Encontrar e remover o lead
            if (userResult && userResult.leads) {
                const leadIndex = userResult.leads.findIndex(lead => lead.id === id);
                userResult.leads.splice(leadIndex, 1);
            }
      
            // Atualizar o estado com a lista atualizada
            setPartialResults(updatedResults);
            setAlertMessage({message: response.message ? response.message : "Lead removido com sucesso!", severity: 'success'});
            setShowAlert(true); 
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar deletar o lead', severity: 'error'});
          setShowAlert(true);
        } finally {
          setOpenDeleteDialog(false);
        }
      };      

    const handleOpenDeleteDialog = (id: number) => {
        setDeleteLeadId(id);
        setOpenDeleteDialog(true);
      };

    const handleCloseDialog = (setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
        setOpenDialog(false);
      };

  return (
    <Box>
      {showAlert && (
        <CustomSnackbar
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message={alertMessage?.message || 'Ocorreu um erro'}
          severity={alertMessage?.severity}
        />
      )}  

      {isLoading || campaignDetails === undefined ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <CustomProgress />
          </Box>
      ) : (        
          <>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">{campaignDetails.campaign.name}</Typography>
                              <Typography variant="body1" sx={{py: '1rem'}} color="textSecondary">{campaignDetails.campaign.description}</Typography>
                              <Grid container spacing={1} mt=".5rem">
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Início</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.startsAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Término</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.expiresAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4} 
                                    sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}
                                  >
                                      <CustomBadge color={getCampaignStatusColor(campaignDetails.campaign.status)} message={campaignDetails.campaign.status} />
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={12}>
                                      <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Critério de Desempate</Typography>
                                      <List>
                                          {campaignDetails.campaign.rules.map((rule, index) => (
                                              <ListItem key={rule.id}>
                                                  <ListItemText primary={`${index + 1}. ${rule.name}`} color="textSecondary"/>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </Grid>
                              </Grid>
                          </CardContent>

                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Total Participantes</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.participants}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Máx. de Vencedores</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.campaign.maxWinners}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Min. de Indicações</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.campaign.targetIndication}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  

              </Grid>

      {userIndex !== undefined && (
        <>

          <CustomCard title="Minha Classificação" icon={<Person />}>
            <StyledTableContainer>
              <StyledTable>
                  <TableHead>
                      <TableRow >
                          <StyledTableHeadCell>Posição</StyledTableHeadCell>
                          <StyledTableHeadCell >Nome</StyledTableHeadCell>
                          <StyledTableHeadCell >Leads Cadastrados</StyledTableHeadCell>
                          <StyledTableHeadCell >Leads Convertidos</StyledTableHeadCell>
                          <StyledTableHeadCell >Ticket Médio</StyledTableHeadCell>
                          <StyledTableHeadCell></StyledTableHeadCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {userPartialResults.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={6} align="center">
                          Você ainda não está participando desta campanha.
                        </TableCell>
                      </TableRow>
                    ) : (
                      
                      userPartialResults.map((person, index) => {
                          let CustomTableRow;
                          const maxWinners = campaignDetails.campaign.maxWinners;
                          const targetIndication = campaignDetails.campaign.targetIndication;
                          const position = userIndex + 1;
                                
                          
                          if (position <= maxWinners) {
                            if (userIndex === 0) {
                              CustomTableRow = GoldRow;
                            } else if (userIndex === 1) {
                              CustomTableRow = SilverRow;
                            } else if (userIndex === 2) {
                              CustomTableRow = BronzeRow;
                            } else {
                              CustomTableRow = TableRow;
                            }
                          } else {
                            CustomTableRow = TableRow;
                          }

                          return (
                          <React.Fragment key={person.user_id}>
                              <CustomTableRow sx={{ height: '70px' }}>
                                  <TableCell style={{ width: '15%' }}>
                                      <Box display="flex" alignItems="center">
                                          {position <= maxWinners && userIndex < 3 && person.leads_conv >= targetIndication ? (
                                          <React.Fragment>
                                              {userIndex === 0 && <GoldIcon as={TrophyIcon} />}
                                              {userIndex === 1 && <SilverIcon as={MedalIcon} />}
                                              {userIndex === 2 && <BronzeIcon as={MedalIcon} />}
                                          </React.Fragment>
                                          ) : (
                                          <Box />
                                          )}
                                          <Box ml={2}>
                                            {person.leads_conv >= targetIndication ?  position + 'º' : 
                                            <Tooltip title="Não Elegível">
                                              <IconButton onClick={() => setOpenDialog(true)} >
                                                <ErrorIcon sx={{color: 'error.light'}}/>
                                              </IconButton>
                                            </Tooltip>}
                                          </Box>
                                      </Box>
                                  </TableCell>
                                  <TableCell>{person.name}</TableCell>
                                  <TableCell>{person.leads_total}</TableCell>
                                  <TableCell>{person.leads_conv}</TableCell>
                                  <TableCell>{person.ticket_avg ? `R$ ${person.ticket_avg.toFixed(2)}` : ''}</TableCell>
                                  <TableCell>
                                  {person.user_id === userId && (
                                      <IconButton aria-label="expand row" size="small" onClick={() => handleShowLeadsUser(index)}>
                                        {openRowsUser[index] ? <Tooltip title="Esconder Leads"><KeyboardArrowUpIcon /></Tooltip> : <Tooltip title="Mostrar Leads"><KeyboardArrowDownIcon /></Tooltip>}
                                      </IconButton>
                                  )}
                                  </TableCell>

                              </CustomTableRow>
                              <TableRow>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                  <Collapse in={openRowsUser[index]} timeout="auto" unmountOnExit>
                                  <Box margin={1}>
                                      <Typography variant="h6" gutterBottom component="div">
                                      Leads
                                      </Typography>
                                      <StyledTable size="small" aria-label="leads">
                                      <TableHead>
                                          <TableRow>
                                              <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                              <StyledTableHeadCell >Produto</StyledTableHeadCell>
                                              <StyledTableHeadCell align='center'>Situação</StyledTableHeadCell>
                                              <StyledTableHeadCell >Valor Convertido</StyledTableHeadCell>
                                              <StyledTableHeadCell  ></StyledTableHeadCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                              {person.leads && person.leads.length > 0 ? (
                                                  person.leads.map((lead) => (
                                                      <TableRow key={lead.id} sx={{ height: '50px' }}>
                                                          <TableCell>{lead.name}</TableCell>
                                                          <TableCell>{getProductName(lead.productId)}</TableCell>
                                                          <TableCell align='center'>
                                                            <CustomBadge color={getLeadStatusColor(lead.status)} message={lead.status} />
                                                          </TableCell>
                                                          <TableCell>{lead.planValue ? `R$ ${Number(lead.planValue).toFixed(2)}` : ''}</TableCell>
                                                          <TableCell align='right'>
                                                              {lead.status !== 'Ativo' && (  
                                                                  <Button 
                                                                  sx={{mr: '1rem'}} 
                                                                  variant="outlined" 
                                                                  startIcon={<PersonRemoveIcon />} 
                                                                  onClick={() => handleOpenDeleteDialog(lead.id)}
                                                                  >
                                                                      Remover Lead
                                                                  </Button>                                                          
                                                            
                                                              )}
                                                          </TableCell>
                                                      </TableRow>
                                                  ))
                                              ) : (
                                                  <TableRow>
                                                      <TableCell colSpan={5} align="center">
                                                          Nenhum Lead Cadastrado
                                                      </TableCell>
                                                  </TableRow>
                                              )}
                                          </TableBody>
                                      </StyledTable>
                                  </Box>
                                  </Collapse>
                              </TableCell>
                              </TableRow>
                          </React.Fragment>
                          );
                      })
                    )}
                  </TableBody>
              </StyledTable>
            </StyledTableContainer>
          </CustomCard>
        </>
      )}

          <CustomCard title="Classificação Geral" icon={<Leaderboard />}>
            <StyledTableContainer >
                <StyledTable >
                    <TableHead>
                        <TableRow >
                            <StyledTableHeadCell>Posição</StyledTableHeadCell>
                            <StyledTableHeadCell >Nome</StyledTableHeadCell>
                            <StyledTableHeadCell >Leads Cadastrados</StyledTableHeadCell>
                            <StyledTableHeadCell >Leads Convertidos</StyledTableHeadCell>
                            <StyledTableHeadCell >Ticket Médio</StyledTableHeadCell>
                            <StyledTableHeadCell></StyledTableHeadCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>                   

                        {partialResults.map((person, index) => {
                            // Verifique se o usuário atual é o usuário logado e defina sua posição
                            if (person.user_id === userId && userIndex === undefined) {
                              setUserIndex(index);
                            }

                            let CustomTableRow;
                            const maxWinners = campaignDetails.campaign.maxWinners;
                            const targetIndication = campaignDetails.campaign.targetIndication;
                            const position = index + 1;
                            
                            if (position <= maxWinners) {
                              if (index === 0) {
                                  CustomTableRow = GoldRow;
                              } else if (index === 1) {
                                  CustomTableRow = SilverRow;
                              } else if (index === 2) {
                                  CustomTableRow = BronzeRow;
                              } else {
                                  CustomTableRow = TableRow;
                              }
                          } else {
                              CustomTableRow = TableRow;
                          }

                            return (
                            <React.Fragment key={person.name}>
                                <CustomTableRow sx={{height: '70px'}}>
                                    <TableCell style={{ width: '15%' }}>
                                        <Box display="flex" alignItems="center">
                                        {position <= maxWinners && index < 3 && person.leads_conv >= targetIndication ? (
                                            <React.Fragment>
                                                {index === 0 && <GoldIcon as={TrophyIcon} />}
                                                {index === 1 && <SilverIcon as={MedalIcon} />}
                                                {index === 2 && <BronzeIcon as={MedalIcon} />}
                                            </React.Fragment>
                                            ) : (
                                            <Box width={24} />
                                            )}
                                              <Box ml={2}>{ person.leads_conv >= targetIndication ? position + 'º' : '' }</Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{person.name}</TableCell>
                                    <TableCell>{person.leads_total}</TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {person.leads_conv}
                                            {person.leads_conv >= targetIndication ? 
                                                <Tooltip title="Elegível"><CheckCircleIcon sx={{color: 'success.light', ml: 1}} /></Tooltip> : 
                                                <Tooltip title="Não Elegível"><ErrorIcon sx={{color: 'error.light', ml: 1}}/></Tooltip>
                                            }
                                        </Box>
                                    </TableCell>
                                    <TableCell>{person.ticket_avg ? `R$ ${person.ticket_avg.toFixed(2)}` : ''}</TableCell>
                                    <TableCell>
                                    {person.user_id === userId && (
                                        <IconButton aria-label="expand row" size="small" onClick={() => handleShowLeads(index)}>
                                          {openRows[index] ? <Tooltip title="Esconder Leads"><KeyboardArrowUpIcon /></Tooltip> : <Tooltip title="Mostrar Leads"><KeyboardArrowDownIcon /></Tooltip>}
                                        </IconButton>
                                    )}
                                    </TableCell>

                                </CustomTableRow>
                                <TableRow>
                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                    <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                    <Box margin={1}>
                                        <Typography variant="h6" gutterBottom component="div">
                                        Leads
                                        </Typography>
                                        <StyledTable size="small" aria-label="leads">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                                <StyledTableHeadCell >Produto</StyledTableHeadCell>
                                                <StyledTableHeadCell >Situação</StyledTableHeadCell>
                                                <StyledTableHeadCell >Valor Convertido</StyledTableHeadCell>
                                                <StyledTableHeadCell  ></StyledTableHeadCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                                {person.leads && person.leads.length > 0 ? (
                                                    person.leads.map((lead) => (
                                                        <TableRow key={lead.id}>
                                                            <TableCell>{lead.name}</TableCell>
                                                            <TableCell>{getProductName(lead.productId)}</TableCell>
                                                            <TableCell>
                                                              <CustomBadge color={getLeadStatusColor(lead.status)} message={lead.status} />
                                                            </TableCell>
                                                            <TableCell>{lead.planValue ? `R$ ${Number(lead.planValue).toFixed(2)}` : ''}</TableCell>
                                                            <TableCell>
                                                                {lead.status !== 'Ativo' && (  
                                                                    <Button 
                                                                    sx={{mr: '1rem'}} 
                                                                    variant="outlined" 
                                                                    startIcon={<PersonRemoveIcon />} 
                                                                    onClick={() => handleOpenDeleteDialog(lead.id)}
                                                                    >
                                                                        Remover Lead
                                                                    </Button>                                                          
                                                              
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                ) : (
                                                    <TableRow>
                                                        <TableCell colSpan={5} align="center">
                                                            Nenhum Lead Cadastrado
                                                        </TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </StyledTable>
                                    </Box>
                                    </Collapse>
                                </TableCell>
                                </TableRow>
                            </React.Fragment>
                            );
                        })}
                    </TableBody>
                </StyledTable>
                <CustomDialog
                        open={openDeleteDialog}
                        onClose={() => handleCloseDialog(setOpenDeleteDialog)}
                        title="Remover Lead"
                        defaultValues={{}}
                        cancelButtonText='Cancelar'
                        confirmButtonText='Confirmar'
                        disabled={showAlert}
                        onSubmit={() => {
                            if (deleteLeadId !== null) {
                                handleDeleteLead(deleteLeadId);
                            }
                        }}
                    >
                        <p>Tem certeza de que deseja remover esse lead?</p>
                </CustomDialog>
            </StyledTableContainer>
          </CustomCard>
        </>
        )}

        <CustomDialog
            open={openDialog}
            onClose={() => handleCloseDialog(setOpenDialog)}
            title="Você está quase lá!"
            cancelButtonText='Cancelar'
            confirmButtonText='Indicar'
            onSubmit={() => navigate(`/indique-sempre`)}
            maxWidth={'xs'}
            >
          
          <Typography>
            Ainda não atingiu a quantidade mínima de indicações convertidas para se qualificar nesta campanha, mas continue indicando e logo estará apto. Estamos torcendo por você!
          </Typography>
          
        </CustomDialog>
    </Box>
  );
};