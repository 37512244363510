import { Box, Container, TableCell, TableRow, TextField } from "@mui/material";
import { styled } from '@mui/system';

export const StyledContainer = styled(Container)(({ theme }) => ({
  overflowX: 'auto',
  width: 'calc(100vw - 3rem)',
  padding: '0'
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  FormHelperTextProps: {
    root: {
      marginLeft: 0,
    },
  },
}));

export const StyledTableContainer = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  overflow: 'auto',
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  paddingBottom: '0.5rem'
}));


export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  '&.MuiTableCell-head': {
    fontWeight: 600,
  },
  '&.MuiTableCell-body': {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));