import { Button, Card, Container, TextField } from "@mui/material";
import { styled } from '@mui/system';
 
export const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
  }));

  export const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
    height: "100%",
    padding: '2rem',
  }));

export const StyledForm = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  FormHelperTextProps: {
    root: {
      marginLeft: 0,
    },
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    padding: theme.spacing(1.5),
}));