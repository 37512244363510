import { Avatar, Box, Card } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/system';

export const StyledCard = styled(Card)(({ theme }) => ({
  color: 'rgba(0, 0, 0, 0.87)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '0px',
  overflowWrap: 'break-word',
  backgroundColor: 'rgb(255, 255, 255)',
  backgroundClip: 'border-box',
  border: '0px solid rgba(0, 0, 0, 0.125)',
  borderRadius: '0.75rem',
  boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
  overflow: 'visible',
  minHeight: '200px',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '1rem'
}));

export const Title = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '.5rem 1rem'
}));


export const RoundedAvatar = styled(Avatar)(({ theme, color }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  lineHeight: 1,
  overflow: 'hidden',
  userSelect: 'none',
  transition: 'all 200ms ease-in-out 0s',
  background: color 
    ? `linear-gradient(45deg, ${color} 50%, ${alpha(color, 0.9)} 90%)`
    : 'linear-gradient(195deg, rgb(96, 160, 175), rgb(80, 144, 158))',
  color: 'rgb(255, 255, 255)',
  fontWeight: 400,
  width: '4.625rem',
  height: '4.625rem',
  fontSize: '1rem',
  padding: '8px',
  marginTop: '-1rem',
  borderRadius: '0.75rem',
  marginLeft: '1rem'
}));
