import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const CopyrightBox = styled(Box)({
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap', 
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    marginTop: '1.5rem',
    '@media (max-width: 600px)': { 
      flexWrap: 'wrap',
      justifyContent: 'center',
    }
  });
  