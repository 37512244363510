import { Container, SxProps, Theme, Typography } from "@mui/material";
import { Header, RoundedAvatar, StyledCard, Title } from "./styles";

interface CustomCardProps {
    title?: string;
    subtitle?: string;
    children?: React.ReactNode;
    icon?: React.ReactElement; 
    avatarString?: string;
    onClick?: () => void;
    sx?: SxProps<Theme>; 
    avatarColor?: string; 
}


export const CustomCard = ({ title, subtitle, children, icon, avatarString, onClick, avatarColor, sx }: CustomCardProps) => {
  return (
      <Container disableGutters sx={{my: '2rem'}}>
        <StyledCard onClick={onClick} sx={{ ...(sx as any), cursor: onClick ? 'pointer' : 'default' }}>
          <Header>
            <RoundedAvatar color={avatarColor}>
              {icon ? icon : avatarString?.charAt(0).toUpperCase()}
            </RoundedAvatar>
            <Title>
              <Typography variant="h6">{title}</Typography>
              <Typography variant="body2" color="textSecondary">{subtitle}</Typography>
            </Title>
          </Header>
          {children} 
        </StyledCard>
      </Container>
    );
  }

  
