import { Campaign, Visibility } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PublishIcon from '@mui/icons-material/Publish';
import { Box, Button, Collapse, FormControl, Grid, IconButton, InputLabel, Select, TableBody, TableCell, TableFooter, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { createCampaign, createCampaignFinalResult, deleteCampaign, listCampaignRules, listCampaignSnapshotResults, listCampaigns, listUsers, updateCampaign } from '../../../api';
import { CustomBadge } from '../../../components/CustomBadge/CustomBadge';
import { CustomCard } from '../../../components/CustomCard/CustomCard';
import { CustomDialog } from '../../../components/CustomDialog/CustomDialog';
import { CustomProgress } from '../../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import { formatDate, formatTime, getCampaignStatusColor } from '../../../utils';
import { StyledButton, StyledTable, StyledTableContainer, StyledTableHeadCell } from './styles';


interface Result {
    id: number;
    userId: number;
    campaignId: number;
    isDisclosed: boolean;
    participants: any;
    createdAt: string;
  }

export interface Campaign {
    id: number;
    name: string;
    startsAt: string;
    expiresAt: string;
    createdAt: string;
    updatedAt: string;
    targetIndication: number;
    maxWinners: number;
    description: string;
    rules: {
        campaign_rules: any; id: number; position: number 
}[];
    status: string;
    results: Result[]; 
}

interface RuleOption {
    id: number;
    name: string;
    field: string;
    createdAt: null;
    updatedAt: null;
  }


export const Campaigns = () => {
    const navigate = useNavigate();

    const [selectedRules, setSelectedRules] = useState<number[]>([0]); 


    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [currentCampaign, setCurrentCampaign] = useState<Campaign>();

    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);    
    const [openPublishDialog, setOpenPublishDialog] = useState(false);

    const [deleteCampaignId, setDeleteCampaignId] = useState<number | null>(null);
    const [publishCampaignId, setPublishCampaignId] = useState<number | null>(null);
    const [openRows, setOpenRows] = useState<Record<number, boolean>>({});
    const [users, setUsers] = useState<{ id: number; name: string }[]>([]);
    const [ruleOptions, setRuleOptions] = useState<RuleOption[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();  


    const fetchCampaigns = async () => {
        setIsLoading(true);
        try {
            const response = await listCampaigns();
            if (response.res) {
                setCampaigns(response.data);
            } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar carregar campanhas', severity: 'error'});
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCampaignRules = async () => {
        setIsLoading(true);
        try {
            const response = await listCampaignRules();
            if (response.res) {
                setRuleOptions(response.data);
            } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar carregar critérios de desempate', severity: 'error'});
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchUsers = async () => {
        try {
          setIsLoading(true);
          const response = await listUsers();
          if (response.res) {
            setUsers(response.data);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar listar usuários', severity: 'error'});
          setShowAlert(true);
  
        } finally {
          setIsLoading(false);
        }
      };

    useEffect(() => {
        fetchCampaigns();
        fetchCampaignRules();
        fetchUsers();
    }, []);

    const getUserName = (userId: number) => {
        const userName = users.find(u => u.id === userId);
        return userName ? userName.name : 'Usuário Não Encontrado';
      };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required("Nome da Campanha é obrigatória"),
        startsAt: yup
            .date()
            .required("Data de início é obrigatória"),
        expiresAt: yup
            .date()
            .required("Data de término é obrigatória")
            .min(yup.ref('startsAt'), "Data de término deve ser depois da data de início"),
        maxWinners: yup
            .number()
            .required('A quantidade máxima de vencedores é obrigatória')
            .min(1, 'A quantidade máxima de vencedores deve ser pelo menos 1'),
        targetIndication: yup
            .number()
            .required('A quantidade mínima de indicações é obrigatória')
            .min(1, 'A quantidade mínima de indicações deve ser pelo menos 1'),
        rules: yup
            .array()
            .of(yup.number())
            .test('has-selected-rule', 'Pelo menos um critério de desempate deve ser selecionado', (value) => {
              return value ? value.some((rule) => rule !== 0) : false;
            })
            .required('O critério de desempate é obrigatório'),
        description: yup
            .string()
            .required('A descrição é obrigatória')
            .max(2000, 'A descrição deve ter no máximo 2000 caracteres'),
      });

      const createFormik = useFormik({
        initialValues: {
          name: '',
          startsAt: '',
          expiresAt: '',
          rules: [0],
          maxWinners: 1,
          targetIndication: 1,
          description: '',
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: async (values) => {
          try {
            const data = {
              name: values.name,
              startsAt: values.startsAt,
              expiresAt: values.expiresAt,
              description: values.description || null,
              targetIndication: values.targetIndication,
              maxWinners: values.maxWinners,
              rules: values.rules.map((ruleId, index) => ({
                id: ruleId,
                position: index + 1,
              })),
            };
      
            const response = await createCampaign(data);
            if (response.res === true) {
              await fetchCampaigns();
              setAlertMessage({message: response.message ? response.message : "Campanha cadastrada com sucesso!", severity: 'success'});
              setShowAlert(true); 
            } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
            }
          } catch (error) {
            setAlertMessage({message: 'Erro ao tentar criar a campanha', severity: 'error'});
            setShowAlert(true); 
          } finally {
            setOpenCreateDialog(false);
          }
        },
      });  

    const editFormik = useFormik({
        initialValues: {
            name: '',
            startsAt: '',
            expiresAt: '',
            targetIndication: 1,
            maxWinners: 1,
            rules: [0],
            description: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (!currentCampaign) return;
            try {
                const id = currentCampaign.id;
                const data = {
                  name: values.name,
                  startsAt: values.startsAt,
                  expiresAt: values.expiresAt,
                  description: values.description,
                  targetIndication: values.targetIndication,
                  maxWinners: values.maxWinners,
                  rules: values.rules.map((ruleId, index) => ({
                    id: ruleId,
                    position: index + 1,
                  })),                
                };
                const response = await updateCampaign(id, data);
                if (response.res === true) {
                    await fetchCampaigns();
                    setOpenEditDialog(false);
                    setAlertMessage({message: response.message ? response.message : "Campanha atualizada com sucesso!", severity: 'success'});
                    setShowAlert(true); 
                } else {
                    setAlertMessage({message: response.message, severity: 'error'});
                    setShowAlert(true);
                }
              } catch (error) {
                setAlertMessage({message: 'Erro ao tentar editar campanha', severity: 'error'});
                setShowAlert(true);
              } finally {
                setOpenEditDialog(false);
              }
          },        
    });      
    

    const handleOpenCreateDialog = () => {
        createFormik.resetForm();
        setOpenCreateDialog(true);
    }; 

    const handleOpenEditDialog = (campaign: Campaign) => {

        setCurrentCampaign(campaign);
        campaign.rules.sort((a,b) => (a.campaign_rules.position > b.campaign_rules.position) ? 1 : ((b.campaign_rules.position > a.campaign_rules.position) ? -1 : 0))
        const ruleIds = campaign.rules.map(rule => rule.id);

      
        editFormik.resetForm({
          values: {
            name: campaign.name,
            startsAt: new Date(campaign.startsAt).toISOString().split('T')[0],
            expiresAt: new Date(campaign.expiresAt).toISOString().split('T')[0],
            targetIndication: campaign.targetIndication,
            maxWinners: campaign.maxWinners,
            rules: ruleIds,
            description: campaign.description,
          },
        });
        setOpenEditDialog(true);
    };  
    
    const handleOpenDeleteDialog = (id: number) => {
        setDeleteCampaignId(id);
        setOpenDeleteDialog(true);
    };

    const handleDeleteCampaign = async (id: number) => {
        try {
          const response = await deleteCampaign(id);
          if (response.res) {
              await fetchCampaigns();
              setOpenDeleteDialog(false);
              setAlertMessage({message: response.message ? response.message : "Campanha deletada com sucesso!", severity: 'success'});
              setShowAlert(true); 
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar editar campanha', severity: 'error'});
            setShowAlert(true);
        } finally {
          setOpenDeleteDialog(false);
        }
    };    

    const handleCloseDialog = (setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
        setOpenDialog(false);
    };

    const handleCreateRuleChange = (index: number, value: number) => {
        const newRules = [...createFormik.values.rules];
        newRules[index] = value;
        createFormik.setFieldValue('rules', newRules);
        setSelectedRules(newRules);
    };

    const handleEditRuleChange = (index: number, value: number) => {
        const newRules = [...editFormik.values.rules];
        newRules[index] = value;
        editFormik.setFieldValue('rules', newRules);
    };
      

    const handleAddRuleField = () => {
        createFormik.setFieldValue('rules', [...createFormik.values.rules, 0]);
        editFormik.setFieldValue('rules', [...editFormik.values.rules, 0]);
    };

      
    const handleRemoveRuleField = (index: number) => {
        const newCreateRules = [...createFormik.values.rules];
        newCreateRules.splice(index, 1);
        createFormik.setFieldValue('rules', newCreateRules);

        const newEditRules = [...editFormik.values.rules];
        newEditRules.splice(index, 1);
        editFormik.setFieldValue('rules', newEditRules);
    };

    const handleCreateCampaignFinalResult = async (resultId: number) => {
        try {
          const response = await createCampaignFinalResult(resultId);
          if (response.res) {
            navigate(0);
            setOpenPublishDialog(false);
            setAlertMessage({message: response.message ? response.message : "Resultado da campanha divulgado com sucesso", severity: 'success'});
            setShowAlert(true);  
        } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
            setAlertMessage({message: 'Ocorreu um erro ao divulgar o resultado da campanha', severity: 'error'});
            setShowAlert(true);
        } finally {
            setOpenPublishDialog(false);
        }
    };  
    

    const handleShowResults = async (campaignId: number, index: number) => {
        const isOpen = !openRows[index];
        setOpenRows({ ...openRows, [index]: isOpen });
      
        if (isOpen) {
            if (!campaigns[index].results) {
                try {
                  const response = await listCampaignSnapshotResults(campaigns[index].id);
                  if (response.res) {
                    const filteredResults = response.data.filter((result: { campaignId: number; }) => result.campaignId === Number(campaignId));

                    const updatedCampaigns = [...campaigns];
                    updatedCampaigns[index].results = filteredResults;
                    setCampaigns(updatedCampaigns);
                  } else {
                  }
                } catch (error) {
                }
            }
        }
    };    
    
    const handleOpenPublishDialog = (ResultId: number) => {
        setPublishCampaignId(ResultId);
        setOpenPublishDialog(true);
    };

      

    return (
        <Box>         
            {showAlert && (
                <CustomSnackbar
                open={showAlert}
                onClose={() => setShowAlert(false)}
                message={alertMessage?.message || 'Ocorreu um erro'}
                severity={alertMessage?.severity}
                />
            )}

            <Box>                    
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                        <CustomProgress />
                    </Box>
                ) : (

                        <>
                        <Box display="flex" justifyContent="flex-end" mb={2}>
                            <Button
                                variant="contained"
                                onClick={() => handleOpenCreateDialog()}
                                startIcon={<AddIcon />}
                            >
                                Cadastrar Campanha
                            </Button>
                        </Box>

                        <CustomCard title="Campanhas" subtitle="Gerenciamento de campanha" icon={<Campaign />}>
                            <StyledTableContainer>
                                <StyledTable>
                                    <TableHead >
                                        <TableRow>
                                            <StyledTableHeadCell>ID</StyledTableHeadCell>
                                            <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                            <StyledTableHeadCell >Data de início</StyledTableHeadCell>
                                            <StyledTableHeadCell >Data de término</StyledTableHeadCell>
                                            <StyledTableHeadCell align='center'>Situação</StyledTableHeadCell>
                                            <StyledTableHeadCell >Ações</StyledTableHeadCell>
                                            <StyledTableHeadCell></StyledTableHeadCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {campaigns.map((campaign, index) => (
                                            <React.Fragment key={campaign.id}>
                                                <TableRow>
                                                    <TableCell>{campaign.id}</TableCell>
                                                    <TableCell>{campaign.name}</TableCell>
                                                    <TableCell>{campaign.startsAt ? formatDate(campaign.startsAt) : ''}</TableCell>
                                                    <TableCell>{campaign.expiresAt ? formatDate(campaign.expiresAt) : ''}</TableCell>
                                                    <TableCell align='center'>
                                                        <CustomBadge color={getCampaignStatusColor(campaign.status)} message={campaign.status} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Tooltip title="Visualizar Campanha" >
                                                                <IconButton onClick={() => navigate(`/campanhas/${campaign.id}`)}>
                                                                    <Visibility />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Editar Campanha">
                                                                <IconButton onClick={() => handleOpenEditDialog(campaign)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Remover Campanha">
                                                                <IconButton onClick={() => handleOpenDeleteDialog(campaign.id)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => handleShowResults(campaign.id, index)}>
                                                            {openRows[index] ? <Tooltip title="Esconder Resultados"><KeyboardArrowUpIcon /></Tooltip> : <Tooltip title="Mostrar Resultados"><KeyboardArrowDownIcon /></Tooltip>}
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                                        <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Resultados
                                                                </Typography>
                                                                <StyledTable size="small" aria-label="results">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell>Gerado por</TableCell>
                                                                            <TableCell>Gerado em</TableCell>
                                                                            <TableCell></TableCell>
                                                                            <TableCell>Ações</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {campaign.results && campaign.results.length > 0 ? (
                                                                            campaign.results.map((result) => (
                                                                                <TableRow key={result.id}>
                                                                                    <TableCell>{getUserName(result.userId)}</TableCell>
                                                                                    <TableCell>{formatDate(result.createdAt) + " - " + formatTime(result.createdAt)}</TableCell>
                                                                                    <TableCell>
                                                                                        {result.isDisclosed === true && (
                                                                                            <CustomBadge color="success" message="Publicado" />
                                                                                        )}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Tooltip title="Visualizar Resultado">
                                                                                            <IconButton onClick={() => navigate(`/campanhas/${campaign.id}/resultados/${result.id}`)}>
                                                                                                <Visibility />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        {campaign.status === 'Em análise' && (
                                                                                            <Tooltip title="Publicar Resultado">
                                                                                                <IconButton onClick={() => handleOpenPublishDialog(result.id)}>
                                                                                                    <PublishIcon />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))
                                                                        ) : (
                                                                            <TableRow>
                                                                                <TableCell colSpan={5} align="center">
                                                                                    Nenhum Resultado Cadastrado
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )}

                                                                    </TableBody>
                                                                </StyledTable>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </React.Fragment>
                                        ))}
                                        {campaigns.length === 0 && (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">Nenhuma campanha encontrada.</TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                    </TableFooter>
                                </StyledTable>
                            </StyledTableContainer>
                        </CustomCard>
                            </>
                
                    )}

                {/* Create */}
                <CustomDialog
                open={openCreateDialog}
                onClose={() => handleCloseDialog(setOpenCreateDialog)}
                title="Cadastrar Campanha"
                onSubmit={createFormik.handleSubmit}
                cancelButtonText='Cancelar'
                confirmButtonText='Cadastrar'
                maxWidth={'md'}
                >
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                        <TextField 
                            name="name"
                            label="Nome da Campanha"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={createFormik.values.name}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.name && Boolean(createFormik.errors.name)}
                            helperText={createFormik.touched.name && createFormik.errors.name}
                        />            
                        </Grid>

                        <Grid item xs={12} sm={3}>
                        <TextField 
                            name="startsAt"
                            label="Data de Início"
                            type="date"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value={createFormik.values.startsAt}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.startsAt && Boolean(createFormik.errors.startsAt)}
                            helperText={createFormik.touched.startsAt && createFormik.errors.startsAt}
                        />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                        <TextField 
                            name="expiresAt"
                            label="Data de Fim"
                            type="date"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                            shrink: true,
                            }}
                            value={createFormik.values.expiresAt}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.expiresAt && Boolean(createFormik.errors.expiresAt)}
                            helperText={createFormik.touched.expiresAt && createFormik.errors.expiresAt}
                        />
                        </Grid>

                        <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            name="description"
                            label="Descrição"
                            type="description"
                            id="description"
                            value={createFormik.values.description}
                            onChange={createFormik.handleChange}
                            error={createFormik.touched.description && Boolean(createFormik.errors.description)}
                            helperText={createFormik.touched.description && createFormik.errors.description}
                            inputProps={{ maxLength: 2000 }}
                        />
                    </Grid>
                    
                        
                    </Grid>

                    <Grid container columnSpacing={2} mb={'1rem'}>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="maxWinners"
                            label="Quantidade de Vencedores"
                            fullWidth
                            type='number'
                            margin="normal"
                            variant="outlined"
                            value={createFormik.values.maxWinners}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.maxWinners && Boolean(createFormik.errors.maxWinners)}
                            helperText={createFormik.touched.maxWinners && createFormik.errors.maxWinners}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="targetIndication"
                            label="Indicações Convertidas"
                            fullWidth
                            type='number'
                            margin="normal"
                            variant="outlined"
                            value={createFormik.values.targetIndication}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.targetIndication && Boolean(createFormik.errors.targetIndication)}
                            helperText={createFormik.touched.targetIndication && createFormik.errors.targetIndication}
                            />
                        </Grid>
                    </Grid>

                    {createFormik.values.rules.map((selected, index) => (
                    <Box display="flex" alignItems="center" mt={2} key={index}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" flexGrow={1}>
                        <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor={`select-rule-${index}`}>{`${index + 1}º Critério de Desempate`}</InputLabel>
                        <Select
                            native
                            fullWidth
                            id={`select-rule-${index}`}
                            name={`rules[${index}]`}
                            label={`${index + 1}º Critério de Desempate`}
                            value={selected}
                            variant="outlined"
                            disabled={index > 0 && selectedRules[index - 1] === 0}
                            onChange={(event) => handleCreateRuleChange(index, Number(event.target.value))}
                            error={createFormik.touched.rules && Boolean(createFormik.errors.rules)}
                        >
                            <option aria-label="None" value="" />
                            {ruleOptions
                                .filter((option) => !selectedRules.slice(0, index).includes(option.id))
                                .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                        </Select>
                        {createFormik.touched.rules && createFormik.errors.rules && (
                            <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                {createFormik.errors.rules}
                            </Typography>
                        )}
                    </FormControl>

                        </Box>

                        {index > 0 && (
                        <Tooltip title="Remover Critério">
                            <IconButton onClick={() => handleRemoveRuleField(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        )}
                    </Box>          
                        ))}
                        
                    <Box mb={2} display="flex" justifyContent="flex-end">
                        <StyledButton
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleAddRuleField}
                        disabled={
                            createFormik.values.rules.length >= ruleOptions.length ||
                            (createFormik.values.rules[createFormik.values.rules.length - 1] === 0)
                        }         >
                        Adicionar Critério
                        </StyledButton>
                    </Box>

                </CustomDialog>

                {/* Edit */}
                <CustomDialog
                open={openEditDialog}
                onClose={() => handleCloseDialog(setOpenEditDialog)}
                title="Editar Campanha"
                onSubmit={editFormik.handleSubmit}
                cancelButtonText='Cancelar'
                confirmButtonText='Atualizar'
                maxWidth={'md'}
                >
                    
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={6}>
                            <TextField 
                            name="name"
                            label="Nome da Campanha"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            value={editFormik.values.name}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.name && Boolean(editFormik.errors.name)}
                            helperText={editFormik.touched.name && editFormik.errors.name}
                            />            
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField 
                            name="startsAt"
                            label="Data de Início"
                            type="date"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={editFormik.values.startsAt}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.startsAt && Boolean(editFormik.errors.startsAt)}
                            helperText={editFormik.touched.startsAt && editFormik.errors.startsAt}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField 
                            name="expiresAt"
                            label="Data de Fim"
                            type="date"
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={editFormik.values.expiresAt}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.expiresAt && Boolean(editFormik.errors.expiresAt)}
                            helperText={editFormik.touched.expiresAt && editFormik.errors.expiresAt}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            name="description"
                            label="Descrição"
                            type="text"
                            id="description"
                            value={editFormik.values.description}
                            onChange={editFormik.handleChange}
                            error={editFormik.touched.description && Boolean(editFormik.errors.description)}
                            helperText={editFormik.touched.description && editFormik.errors.description}
                            inputProps={{ maxLength: 2000 }}
                        />
                        </Grid>
                    </Grid>


                    <Grid container columnSpacing={2} mb={'1rem'}>
                        <Grid item xs={6}>
                            <TextField
                                name="maxWinners"
                                label="Quantidade de Vencedores"
                                fullWidth
                                type='number'
                                margin="normal"
                                variant="outlined"
                                value={editFormik.values.maxWinners}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                error={editFormik.touched.maxWinners && Boolean(editFormik.errors.maxWinners)}
                                helperText={editFormik.touched.maxWinners && editFormik.errors.maxWinners}
                                />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="targetIndication"
                                label="Indicações Convertidas"
                                fullWidth
                                type='number'
                                margin="normal"
                                variant="outlined"
                                value={editFormik.values.targetIndication}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                error={editFormik.touched.targetIndication && Boolean(editFormik.errors.targetIndication)}
                                helperText={editFormik.touched.targetIndication && editFormik.errors.targetIndication}
                                />
                        </Grid>
                    </Grid>

                    {editFormik.values.rules.map((selectedId, index) => (
                    <Box display="flex" alignItems="center" mt={2} key={index}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" flexGrow={1}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel>{`${index + 1}º Critério de Desempate`}</InputLabel>
                                <Select
                                    native
                                    value={selectedId}
                                    label={`${index + 1}º Critério de Desempate`}
                                    variant="outlined"
                                    onChange={(e) => handleEditRuleChange(index, Number(e.target.value))} 
                                    error={editFormik.touched.rules && Boolean(editFormik.errors.rules)}
                                >
                                <option aria-label="None" value="" /> 
                                {ruleOptions
                                    .filter((option) => !editFormik.values.rules.slice(0, index).includes(option.id))
                                    .map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                    ))}
                                </Select>
                                {editFormik.touched.rules && editFormik.errors.rules && (
                                    <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                        {Array.isArray(editFormik.errors.rules)
                                            ? editFormik.errors.rules.join(' ')
                                            : editFormik.errors.rules}
                                    </Typography>
                                )}
                            </FormControl>
                        </Box>
                        {index > 0 && (
                            <Tooltip title="Remover Critério">
                                <IconButton onClick={() => handleRemoveRuleField(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                ))}

                <Box mb={2} display="flex" justifyContent="flex-end">
                    <StyledButton
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={handleAddRuleField}
                        disabled={
                            editFormik.values.rules.length >= ruleOptions.length ||
                            (editFormik.values.rules[editFormik.values.rules.length - 1] === 0)
                        }                
                    >
                        Adicionar Critério
                    </StyledButton>
                </Box>
                </CustomDialog>

                {/* Delete */}
                <CustomDialog
                    open={openDeleteDialog}
                    onClose={() => handleCloseDialog(setOpenDeleteDialog)}
                    title="Deletar Campanha"
                    defaultValues={{}}
                    cancelButtonText='Cancelar'
                    confirmButtonText='Confirmar'
                    onSubmit={() => {
                        if (deleteCampaignId !== null) {
                            handleDeleteCampaign(deleteCampaignId);
                        }
                    }}
                >
                    <p>Tem certeza de que deseja deletar esta campanha?</p>
                </CustomDialog>

                <CustomDialog
                    open={openPublishDialog}
                    onClose={() => handleCloseDialog(setOpenPublishDialog)}
                    title="Atenção!"
                    cancelButtonText='Cancelar'
                    confirmButtonText='Publicar'
                    onSubmit={() => {
                        if (publishCampaignId !== null) {
                            handleCreateCampaignFinalResult(publishCampaignId);
                        }
                    }}
                    maxWidth={'xs'}
                    >
                
                <Typography>
                    Após publicar o resultado final, não será possível fazer novas publicações. Deseja continuar?
                </Typography>
                
                </CustomDialog>
                    
            </Box>
        </Box>
    );
}

