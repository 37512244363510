import { Group, LocalActivity, PersonAdd } from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Box, Button, FormControl, Grid, InputLabel, OutlinedInput, Select, Switch, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { createCampaignLead, getCampaignFinalResult, getProductsList, listCampaigns } from '../../api';
import { CustomBadge } from '../../components/CustomBadge/CustomBadge';
import { CustomCard } from '../../components/CustomCard/CustomCard';
import { CustomDialog } from '../../components/CustomDialog/CustomDialog';
import { CustomProgress } from '../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../components/CustomSnackbar/CustomSnackbar';
import CpfMask from '../../components/Masks/CpfMask';
import PhoneMask from '../../components/Masks/PhoneMask';
import { CompanyOptions, getCampaignStatusColor, getCompaniesList, isValidCPF, removeMask } from '../../utils';
import { StyledTable, StyledTableContainer, StyledTableHeadCell } from './styles';



export interface Campaign {
    id: number;
    name: string;
    description: string;
    targetIndication: number;
    maxWinners: number;
    startsAt: string;
    expiresAt: string;
    createdAt: string;
    updatedAt: string;
    status: string;
}


export const CCampaigns = () => {
    const navigate = useNavigate();

    const [openDialog, setOpenDialog] = useState(false);

    const [campaignId, setCampaignId] = useState<number | null>(null);
    const [product, setProduct] = useState<string>('');
    const [companiesList, setCompaniesList] = useState<CompanyOptions>({});
    const [productsList, setProductsList] = useState<any[]>([]);
    
    const [campaigns, setCampaigns] = useState<Campaign[]>([]); 
    const [showInactiveCampaigns, setShowInactiveCampaigns] = useState(false);
   
    
    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();  

    // Verificar se há campanhas "Em andamento" ou "Em análise"
    const hasActiveCampaign = campaigns.some(campaign => ["Em andamento", "Em análise"].includes(campaign.status));

    const filteredCampaigns = showInactiveCampaigns 
        ? campaigns.filter(campaign => campaign.status === "Finalizada")
        : campaigns.filter(campaign => ["Em andamento", "Em análise"].includes(campaign.status));        
    

    const validationSchema = yup.object().shape({
        clientName: yup
        .string()
        .required('Nome do cliente é obrigatório'),
        clientCpf: yup
        .string()
        .required('CPF é obrigatório'),
        clientPhone: yup
        .string()
        .required('Telefone do cliente é obrigatório'),
        product: yup
        .string()
        .required('Produto de interesse é obrigatório'),
        company: yup
        .string()
        .required('Empresa de destino é obrigatório'),
        notes: yup
        .string()
        // .required('O campo observações é obrigatório')
        .max(2000, 'A descrição deve ter no máximo 2000 caracteres'),
    });

    const session = sessionStorage.getItem('iv-crm');
    const sessionData = session && JSON.parse(session);
    const collaboratorId = sessionData && sessionData.id;

    const channel = '333471';

    const formik = useFormik({
        initialValues: {
            clientName: '',
            clientCpf: '',
            clientPhone: '',
            clientEmail: '',
            product: '',
            company: '',
            notes: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            try {
                const prefixedClientName = `(Teste) ${values.clientName}`;
                const productId = productsList.find(p => p.slug === values.product)?.id;

                const unmaskedCPF = removeMask(values.clientCpf);
                if (!isValidCPF(unmaskedCPF)) {
                    setAlertMessage({message: 'CPF inválido. Por favor, verifique o número e tente novamente.', severity: 'error'});
                    setShowAlert(true);
                    return;
                }
                const response = await createCampaignLead(
                    prefixedClientName,
                    unmaskedCPF,
                    // values.clientName,
                    Number(campaignId),
                    values.clientPhone,
                    collaboratorId,
                    values.clientEmail,
                    productId,
                    parseInt(values.company, 10),
                    channel,
                    values.notes
                  );                
                  if (response.res === true) {
                    formik.resetForm();
                    setAlertMessage({message: response.message ? response.message : "Lead cadastrado com sucesso!", severity: 'success'});
                    setShowAlert(true); 
                } else {
                    setAlertMessage({message: response.message, severity: 'error'});
                    setShowAlert(true); 
                }
            } catch (error: any) {
                setAlertMessage({message: 'Erro ao tentar cadastrar lead', severity: 'error'});
                setShowAlert(true); 

            } finally {
                setIsLoading(false);
            }
        },
      });


    const fetchCampaigns = async () => {
        setIsLoading(true);
        try {
            const response = await listCampaigns();
            if (response.res) {                
                // Filtrar campanhas que não têm o status "Aguardando"
                const filteredCampaigns = response.data.filter((campaign: Campaign) => campaign.status !== 'Aguardando');
                setCampaigns(filteredCampaigns);
            } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar carregar campanhas', severity: 'error'});
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProducts = async () => {
        const response = await getProductsList();
        if (response.res) {
            setProductsList(response.data);
        } else {
        }
    };

    useEffect(() => {
        fetchProducts();
        fetchCampaigns();
    }, []);
    
    useEffect(() => {
        let companies = getCompaniesList(product);
        setCompaniesList(companies);
    }, [product]);

    useEffect(() => {
        hasActiveCampaign ? setShowInactiveCampaigns(false) : setShowInactiveCampaigns(true)
    }, [hasActiveCampaign]);



    
    const handleGetCampaignFinalResult = async (campaignId: number) => {
        try {
          const response = await getCampaignFinalResult(campaignId);
          if (response.res) {
            const resultId = response.data.id;
            navigate(`/indique-sempre/${campaignId}/resultados/${resultId}`);
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar carregar resultados finais', severity: 'error'});
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
      };


      const handleOpenDialog = (campaignId: number) => {
        setCampaignId(campaignId);
        setOpenDialog(true);
      };

      const handleCloseDialog = (setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
        setOpenDialog(false);
      };

    return (
    <Box>
        {showAlert && (
            <CustomSnackbar
                open={showAlert}
                onClose={() => setShowAlert(false)}
                message={alertMessage?.message || 'Ocorreu um erro'}
                severity={alertMessage?.severity}
            />
        )}
        
        {isLoading || !campaigns.length ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <CustomProgress />
            </Box>
        ) : (
            <>
            {/* {hasActiveCampaign && 
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Countdown expiresAt={campaigns[0]?.expiresAt || ""} />
                </Box>
            } */}
                    
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', m: '1rem 0', p: '0 1rem' }}>
                    <Typography>Mostrar Finalizadas</Typography>
                    <Switch
                        checked={showInactiveCampaigns}
                        onChange={() => setShowInactiveCampaigns(prev => !prev)}
                    />
                </Box>

                <CustomCard title="Indique Sempre" subtitle="Campanha ativa" icon={<LocalActivity />}>
                    <StyledTableContainer>
                        <StyledTable >
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeadCell >Nome</StyledTableHeadCell>
                                    <StyledTableHeadCell >Data de início</StyledTableHeadCell>
                                    <StyledTableHeadCell >Data de término</StyledTableHeadCell>
                                    <StyledTableHeadCell align='center'>Status</StyledTableHeadCell>
                                    <StyledTableHeadCell >Ações</StyledTableHeadCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredCampaigns.map((campaign) => (
                                    <TableRow key={campaign.id}>
                                        <TableCell >{campaign.name}</TableCell>
                                        <TableCell >{campaign.startsAt ? new Date(campaign.startsAt).toLocaleDateString('pt-BR') : ''}</TableCell>
                                        <TableCell >{campaign.expiresAt ? new Date(campaign.expiresAt).toLocaleDateString('pt-BR') : ''}</TableCell>
                                        <TableCell align='center'>
                                            <CustomBadge color={getCampaignStatusColor(campaign.status)} message={campaign.status} />
                                        </TableCell>
                                        <TableCell >
                                            <Box sx={{display: 'flex'}} >
                                            {campaign.status === 'Finalizada' ? (
                                            <Button 
                                                variant="outlined" 
                                                startIcon={<EmojiEventsIcon />} 
                                                onClick={() => handleGetCampaignFinalResult(campaign.id)}
                                            >
                                                Resultado
                                            </Button>
                                            ) : (
                                            <>
                                                <Button 
                                                sx={{mr: '1rem'}} 
                                                variant="outlined" 
                                                startIcon={<PersonAdd />} 
                                                onClick={() => handleOpenDialog(campaign.id)}
                                                >
                                                Indicar
                                                </Button>
                                                <Button 
                                                sx={{mr: '1rem'}} 
                                                variant="outlined" 
                                                startIcon={<Group />} 
                                                onClick={() => navigate(`/indique-sempre/${campaign.id}`)}
                                                >
                                                Visualizar
                                                </Button>
                                            </>
                                            )}

                                            </Box>                                            
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {filteredCampaigns.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">Nenhuma campanha encontrada.</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                </CustomCard>
            </>
            )}

                <CustomDialog
                open={openDialog}
                onClose={() => handleCloseDialog(setOpenDialog)}
                title="Cadastrar Lead"
                onSubmit={formik.handleSubmit}
                cancelButtonText='Cancelar'
                confirmButtonText='Cadastrar'
                maxWidth={'md'}
                >

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                autoComplete="cname"
                                name="clientName"
                                variant="outlined"
                                required
                                fullWidth
                                id="clientName"
                                label="Nome do cliente"
                                autoFocus
                                value={formik.values.clientName}
                                onChange={formik.handleChange}
                                error={formik.touched.clientName && Boolean(formik.errors.clientName)}
                                helperText={formik.touched.clientName && formik.errors.clientName}
                                
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                autoComplete="cpf"
                                name="clientCpf"
                                variant="outlined"
                                required
                                fullWidth
                                id="clientCpf"
                                label="CPF do Cliente"
                                value={formik.values.clientCpf}
                                onChange={formik.handleChange}
                                error={formik.touched.clientCpf && Boolean(formik.errors.clientCpf)}
                                helperText={formik.touched.clientCpf && formik.errors.clientCpf}
                                InputProps={{
                                    inputComponent: CpfMask as any,
                                }}
                                
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={8}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="clientEmail"
                                label="Email do cliente"
                                name="clientEmail"
                                autoComplete="email"
                                type='email'
                                value={formik.values.clientEmail}
                                onChange={formik.handleChange}
                                error={formik.touched.clientEmail && Boolean(formik.errors.clientEmail)}
                                helperText={formik.touched.clientEmail && formik.errors.clientEmail}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="clientPhone"
                                label="Telefone do cliente"
                                name="clientPhone"
                                autoComplete="phone"
                                value={formik.values.clientPhone}
                                onChange={formik.handleChange}
                                error={formik.touched.clientPhone && Boolean(formik.errors.clientPhone)}
                                helperText={formik.touched.clientPhone && formik.errors.clientPhone}
                                InputProps={{
                                    inputComponent: PhoneMask as any,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <FormControl variant="outlined" sx={{ width: '100%' }} error={formik.touched.company && Boolean(formik.errors.company)}>
                            <InputLabel htmlFor="select-product">Produto de interesse *</InputLabel>
                            <Select
                                native
                                fullWidth
                                id="select-product"
                                name="product"
                                label="Produto de interesse"
                                value={formik.values.product}
                                onChange={event => {
                                    formik.handleChange(event);
                                    setProduct(event.target.value);
                                    formik.setFieldValue('company', '');
                                }}
                                error={formik.touched.product && Boolean(formik.errors.product)}
                            >
                                <option aria-label="None" value="" />
                                {productsList.map((product) => (
                                    <option value={product.slug}>{product.name}</option>
                                ))}
                            </Select>
                            {formik.touched.product && formik.errors.product && (
                                <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                {formik.errors.product}
                                </Typography>
                            )}
                        </FormControl>

                        </Grid>

                        <Grid item xs={12} sm={6}>
                            
                            <FormControl variant="outlined" sx={{ width: '100%' }} error={formik.touched.company && Boolean(formik.errors.company)}>
                                <InputLabel id="demo-simple-select-outlined-label">Unidade de destino *</InputLabel>
                                <Select
                                    native
                                    fullWidth
                                    labelId="select-company-label"
                                    id="select-company"
                                    name="company"
                                    label="Unidade de destino"
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    disabled={product === ''}
                                    error={formik.touched.company && Boolean(formik.errors.company)}
                                    input={
                                        <OutlinedInput
                                            label="Unidade de destino"
                                            sx={{
                                                backgroundColor: product === '' ? 'rgba(0, 0, 0, 0.09)' : 'transparent',
                                                borderColor: formik.touched.company && Boolean(formik.errors.company) ? 'red' : undefined,
                                            }}
                                        />
                                    }
                                >
                                    <option aria-label="None" value="" />
                                    {Object.keys(companiesList).map(function (key, index) {
                                        return (
                                            <option value={companiesList[key]}>{key}</option>
                                        )
                                    })}
                                </Select>
                                {formik.touched.company && formik.errors.company && (
                                    <Typography variant="caption" color="error" ml={'12px'} mt={'4px'}>
                                    {formik.errors.company}
                                    </Typography>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                name="notes"
                                label="Observações *"
                                type="notes"
                                id="notes"
                                value={formik.values.notes}
                                onChange={formik.handleChange}
                                error={formik.touched.notes && Boolean(formik.errors.notes)}
                                helperText={formik.touched.notes && formik.errors.notes}
                                inputProps={{ maxLength: 2000 }}
                            />
                        </Grid>

                    </Grid>
                    
                </CustomDialog>
            </Box>

    );
}

