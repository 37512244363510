import { Box, Link, Typography } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { ReactNode } from 'react';
import CustomBreadcrumbs, { BreadcrumbsProps } from '../CustomBreadcrumbs/CustomBreadcrumbs'; // Ajuste o caminho conforme necessário


interface Props extends BreadcrumbsProps {
    action?: ReactNode;
    heading: string;
    moreLink?: string | string[];
    sx?: SystemStyleObject;
  }

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}: Props) {
  return (
    <Box sx={{ mb: "20px", ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h6" fontWeight={700} gutterBottom>
            {heading}
          </Typography>
          <CustomBreadcrumbs links={links} {...other} />
        </Box>

        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      <Box sx={{ mt: 2 }}>
        {typeof moreLink === 'string' ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2" fontWeight={400}>
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
