export const getCampaignStatusColor = (status: string): 'error' | 'warning' | 'info' | 'success' => {
    switch (status) {
      case 'Em andamento':
        return 'success';
      case 'Em análise':
        return 'warning';
      case 'Finalizada':
        return 'info';
      default:
        return 'warning';
    }
  };

export const getLeadStatusColor = (status: string): 'error' | 'warning' | 'info' | 'success' => {
    switch (status) {
      case 'Ativo':
        return 'success';
      case 'Novo':
        return 'info';
      case 'Duplicado':
        return 'warning';
      default:
        return 'error';
    }
  };