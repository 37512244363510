import { Divider, Drawer, IconButton, ListItemButton, IconButtonProps as MuiIconButtonProps, Theme } from "@mui/material";
import { styled } from "@mui/system";

  interface IconButtonProps extends MuiIconButtonProps {
    open?: boolean;
  }

export const drawerWidth = 300;


export const Root = styled('div')(({ theme }) => ({
    display: "flex"
}));


export const StyledIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })<IconButtonProps>(({ theme, open }) => ({
    marginRight: theme.spacing(2),
    ...(open && {
        display: "none"
    })
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        // boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
        width: drawerWidth, 
        height: 'calc(100vh - 2rem)',
        margin: '1rem',
        borderRadius: '12px',
        border: '0px'
    }
}));

export const DrawerHeader = styled('div')(({ theme }: { theme: Theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
    margin: '0 16px',
    borderRadius: '8px',
    color: theme.palette.primary.main,
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold',
      color: '#f2f2f2',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  }));

export const StyledDivider = styled(Divider)(({ theme }) => ({
    flexShrink: 0,
    borderTop: '0px solid rgba(0, 0, 0, 0.12)',
    borderRight: '0px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '0px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: 'transparent',
    height: '0.0625rem',
    borderBottom: 'none',
    opacity: 0.25,
    backgroundImage: 'linear-gradient(to right, rgba(52, 71, 103, 0), rgba(52, 71, 103, 0.5), rgba(52, 71, 103, 0)) !important',
}));