import React from 'react';
import MaskedInput from 'react-text-mask';

const PhoneMask = React.forwardRef((props, ref) => {
    return (
        <MaskedInput
            {...props}
            ref={(input) => {
                if (ref !== null && typeof ref === 'object') {
                    ref.current = input ? input.inputElement : null;
                } else if (typeof ref === 'function') {
                    ref(input ? input.inputElement : null);
                }
            }}
            mask={['(',/\d/,/\d/,')',' ',/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,/\d/,]}
        />
    );
});

export default PhoneMask;
