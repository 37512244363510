import { Group } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, FormControlLabel, Grid, Radio, Table, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { createUser, deleteUser, listUsers, updateUser } from '../../../api';
import { CustomCard } from '../../../components/CustomCard/CustomCard';
import { CustomDialog } from '../../../components/CustomDialog/CustomDialog';
import { CustomProgress } from '../../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import { StyledTableCell, StyledTableContainer, StyledTableHeadCell, StyledTextField } from './styles';


// export interface User {
//     username: string;
//     email: string;
//     id: number;
// }

export interface User {
    id: number;
    name: string;
    username: string;
    email: string;
    password: string;
    roles: Array<string>;
 }

// export interface UserUpdate {
//     password: string;
//     id: number | undefined;
// }

export default function ListUsers() {

    const [users, setUsers] = useState<User[]>([]);
    const [currentUser, setCurrentUser] = useState<User | null>(null);


    const [isLoading, setIsLoading] = useState<boolean>(false);  
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();  

    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false); 

    const [deleteUserId, setDeleteUserId] = useState<number | null>(null);

    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const response = await listUsers();
            if (response.res) {
                setUsers(response.data);
            } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
            }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar carregar usuários', severity: 'error'});
            setShowAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);


    const validationSchema = yup.object().shape({
        name: yup
        .string()
        .required("Preencha o nome"),
        email: yup
        .string()
        .email("Email inválido").required("Preencha o email"),
        username: yup
        .string()
        .required("Preencha o usuário"),
        password: yup
        .string()
        .required("Preencha a senha")
        .min(8, "Senha deve ter pelo menos 8 caracteres"),
        confirmPassword: yup
        .string()
        .oneOf([yup.ref('password')], "As senhas devem corresponder")
        .required("Confirmação de senha é obrigatória"),
        userType: yup.string()
        .required('Selecione um tipo de usuário')
        .oneOf(['common', 'admin'], 'Selecione um tipo de usuário válido')

    });

    const createFormik = useFormik({
        initialValues: {
            name: '',
            email: '',
            username: '',
            password: '',
            confirmPassword: '',
            userType: 'common',
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: async (values) => {
            const roles = values.userType === 'admin' ? ['user', 'admin'] : ['user'];

            const data = {
                name: values.name,
                username: values.username,
                email: values.email,
                password: values.password,
                roles: roles
            }
            try {
                const response = await createUser(data);
                
                if (response.res === true) {
                    setAlertMessage({
                        message: response.message ? response.message : "Usuário cadastrado com sucesso!",
                        severity: 'success'
                    });
                    setShowAlert(true); 
                } else {
                    setAlertMessage({
                        message: response.message,
                        severity: 'error'
                    });
                    setShowAlert(true);
                }
            } catch (error) {
                setAlertMessage({message: 'Erro ao tentar cadastrar usuário', severity: 'error'});
                setShowAlert(true);
            } 
        },
        
    });

    const editFormik = useFormik({
        initialValues: {
            name: '',
            email: '',
            username: '',
            password: '',
            confirmPassword: '',
            userType: 'common',
        },
        validationSchema: validationSchema,
        validateOnBlur: false,
        onSubmit: async (values) => {
            if (!currentUser) return;
            try {
                const roles = values.userType === 'admin' ? ['user', 'admin'] : ['user'];
                const userId = currentUser.id;

                const data = {
                    id: userId,
                    name: values.name,
                    username: values.username,
                    email: values.email,
                    password: values.password,
                    roles: roles
                }
                const response = await updateUser(data);
                if (response.res === true) {
                    await fetchUsers();
                    setOpenEditDialog(false);
                    setAlertMessage({message: response.message ? response.message : "usuário atualizado com sucesso!", severity: 'success'});
                    setShowAlert(true); 
                } else {
                    setAlertMessage({message: response.message, severity: 'error'});
                    setShowAlert(true);
                }
              } catch (error) {
                setAlertMessage({message: 'Erro ao tentar editar usuário', severity: 'error'});
                setShowAlert(true);
              } finally {
                setOpenEditDialog(false);
              }
          },        
    }); 

    const handleOpenCreateDialog = () => {
        createFormik.resetForm();
        setOpenCreateDialog(true);
    }; 

    const handleOpenEditDialog = (user: User) => {
        setCurrentUser(user);
            
        editFormik.resetForm({
          values: {            
            name: user.name,
            email: user.email,
            username: user.username,
            password: user.password,
            confirmPassword: '',
            userType: 'common',
          },
        });
        setOpenEditDialog(true);
    };  

    const handleOpenDeleteDialog = (id: number) => {
        setDeleteUserId(id);
        setOpenDeleteDialog(true);
    };

    const handleCloseDialog = (setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>) => {
        setOpenDialog(false);
    };

    const handleDeleteUser = async (id: number) => {
        try {
          const response = await deleteUser(id);
          if (response.res) {
              await fetchUsers();
              setOpenDeleteDialog(false);
              setAlertMessage({message: response.message ? response.message : "Usuário deletado com sucesso!", severity: 'success'});
              setShowAlert(true); 
          } else {
            setAlertMessage({message: response.message, severity: 'error'});
            setShowAlert(true);
          }
        } catch (error) {
            setAlertMessage({message: 'Erro ao tentar deletar o usuário', severity: 'error'});
            setShowAlert(true);
        } finally {
          setOpenDeleteDialog(false);
        }
    };

    


    return (
            <Box>
            {showAlert && (
                <CustomSnackbar
                open={showAlert}
                onClose={() => setShowAlert(false)}
                message={alertMessage?.message || 'Ocorreu um erro'}
                severity={alertMessage?.severity}
                />
            )}

        {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
                <CustomProgress />
            </Box>
        ) : (

            <>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                    variant="contained"
                    onClick={() => handleOpenCreateDialog()}
                    startIcon={<AddIcon />}
                >
                    Cadastrar Usuário
                </Button>
            </Box>
            <CustomCard title="Usuários" subtitle="Gerenciamento de usuário" icon={<Group />}>
                <StyledTableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableHeadCell>Id</StyledTableHeadCell>
                                <StyledTableHeadCell>Usuário</StyledTableHeadCell>
                                <StyledTableHeadCell>Email</StyledTableHeadCell>
                                <StyledTableHeadCell>Ações</StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.length > 0 && (users.map((user) => (
                                <TableRow key={user.id}>
                                    <StyledTableCell component="th" scope="row">
                                        {user.id}
                                    </StyledTableCell>
                                    <StyledTableCell>{user.username}</StyledTableCell>
                                    <StyledTableCell>{user.email}</StyledTableCell>
                                    <StyledTableCell>
                                        <Box sx={{ display: 'flex' }}>
                                            <Tooltip title="Editar Usuário">
                                                <IconButton onClick={() => { handleOpenEditDialog(user); } }>
                                                    <EditIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Deletar Usuário">
                                                <IconButton onClick={() => { handleOpenDeleteDialog(user.id); } }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </StyledTableCell>
                                </TableRow>
                            )))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            </CustomCard>
            </>
            )}

            {/* Create */}
            <CustomDialog
                open={openCreateDialog}
                onClose={() => handleCloseDialog(setOpenCreateDialog)}
                title="Cadastrar Usuário"
                onSubmit={createFormik.handleSubmit}
                cancelButtonText='Cancelar'
                confirmButtonText='Cadastrar'
                maxWidth={'md'}
                >

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            autoComplete="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            id="firstName"
                            label="Nome"
                            autoFocus
                            value={createFormik.values.name}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.name && !!createFormik.errors.name}
                            helperText={createFormik.touched.name && createFormik.errors.name}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={createFormik.values.email}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.email && !!createFormik.errors.email}
                            helperText={createFormik.touched.email && createFormik.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            id="username"
                            label="Usuário"
                            name="username"
                            value={createFormik.values.username}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.username && !!createFormik.errors.username}
                            helperText={createFormik.touched.username && createFormik.errors.username}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            label="Senha"
                            name="password"
                            autoComplete="password"
                            type="password"
                            id="password"
                            value={createFormik.values.password}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.password && !!createFormik.errors.password}
                            helperText={createFormik.touched.password && createFormik.errors.password}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            label="Confirmar Senha"
                            name="confirmPassword"
                            autoComplete="password"
                            type="password"
                            id="confirm-password"
                            value={createFormik.values.confirmPassword}
                            onChange={createFormik.handleChange}
                            onBlur={createFormik.handleBlur}
                            error={createFormik.touched.confirmPassword && !!createFormik.errors.confirmPassword}
                            helperText={createFormik.touched.confirmPassword && createFormik.errors.confirmPassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    {/* <StyledDivider /> */}
                    <Typography component="h1" variant="h6">
                        Permissões
                    </Typography>
                    <FormControlLabel
                        control={
                            <Radio 
                                name="userType"
                                value="common"
                                checked={createFormik.values.userType === 'common'} 
                                onChange={createFormik.handleChange} 
                            />
                        }
                        label="Comum"
                    />
                    <FormControlLabel
                        control={
                            <Radio 
                                name="userType"
                                value="admin"
                                checked={createFormik.values.userType === 'admin'} 
                                onChange={createFormik.handleChange} 
                            />
                        }
                        label="Administrador"
                    />

                    </Grid>                    
                </Grid>   

            </CustomDialog>

            {/* Edit */}
            <CustomDialog
                open={openEditDialog}
                onClose={() => handleCloseDialog(setOpenEditDialog)}
                title="Editar Usuário"
                onSubmit={editFormik.handleSubmit}
                cancelButtonText='Cancelar'
                confirmButtonText='Atualizar'
                maxWidth={'md'}
                >

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            autoComplete="name"
                            name="name"
                            variant="outlined"
                            fullWidth
                            id="firstName"
                            label="Nome"
                            autoFocus
                            value={editFormik.values.name}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.name && !!editFormik.errors.name}
                            helperText={editFormik.touched.name && editFormik.errors.name}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={editFormik.values.email}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.email && !!editFormik.errors.email}
                            helperText={editFormik.touched.email && editFormik.errors.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            id="username"
                            label="Usuário"
                            name="username"
                            value={editFormik.values.username}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.username && !!editFormik.errors.username}
                            helperText={editFormik.touched.username && editFormik.errors.username}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            label="Nova Senha"
                            name="password"
                            autoComplete="password"
                            type="password"
                            id="password"
                            value={editFormik.values.password}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.password && !!editFormik.errors.password}
                            helperText={editFormik.touched.password && editFormik.errors.password}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={12}>
                        <StyledTextField
                            variant="outlined"
                            fullWidth
                            label="Confirmar Nova Senha"
                            name="confirmPassword"
                            autoComplete="password"
                            type="password"
                            id="confirm-password"
                            value={editFormik.values.confirmPassword}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            error={editFormik.touched.confirmPassword && !!editFormik.errors.confirmPassword}
                            helperText={editFormik.touched.confirmPassword && editFormik.errors.confirmPassword}
                        />
                    </Grid>
                    <Grid item xs={12}>
                    {/* <StyledDivider /> */}
                    <Typography component="h1" variant="h6">
                        Permissões
                    </Typography>
                    <FormControlLabel
                        control={
                            <Radio 
                                name="userType"
                                value="common"
                                checked={editFormik.values.userType === 'common'} 
                                onChange={editFormik.handleChange} 
                            />
                        }
                        label="Comum"
                    />
                    <FormControlLabel
                        control={
                            <Radio 
                                name="userType"
                                value="admin"
                                checked={editFormik.values.userType === 'admin'} 
                                onChange={editFormik.handleChange} 
                            />
                        }
                        label="Administrador"
                    />

                    </Grid>                    
                </Grid>

            </CustomDialog>

            {/* Delete */}
            <CustomDialog
                open={openDeleteDialog}
                onClose={() => handleCloseDialog(setOpenDeleteDialog)}
                title="Deletar Campanha"
                defaultValues={{}}
                cancelButtonText='Cancelar'
                confirmButtonText='Confirmar'
                onSubmit={() => {
                    if (deleteUserId !== null) {
                        handleDeleteUser(deleteUserId);
                    }
                }}
            >
                    <p>Tem certeza de que deseja deletar este usuário?</p>
            </CustomDialog>

        </Box>

    )
}