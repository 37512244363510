import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDrawer } from '../../contexts/DrawerContext';
import CustomBreadcrumbs, { TLink } from "../CustomBreadcrumbs/CustomBreadcrumbs";
import { StyledAppBar } from './styles';

interface CustomAppBarProps {
    title: string;
    breadcrumbs?: TLink[];
}

  export const CustomAppBar = ({ title, breadcrumbs  }: CustomAppBarProps) => {
    
    const theme = useTheme();
    const { open, setOpen } = useDrawer();
    const isLargeEnough = useMediaQuery(theme.breakpoints.up('lg'));

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
    return (
        <StyledAppBar open={isLargeEnough ? true : open}>
        <Toolbar >
        {!isLargeEnough &&
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                >
                <MenuIcon />
            </IconButton>
        }
        <Box>
            <Typography component="h1" fontWeight="bold" noWrap>
                {title}
            </Typography>
            {breadcrumbs && (
            <CustomBreadcrumbs links={breadcrumbs} />
          )}
        </Box>
        

            </Toolbar>
        </StyledAppBar>
  )
}
