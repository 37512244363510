import { Campaign, Leaderboard } from '@mui/icons-material';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrophyIcon from '@mui/icons-material/EmojiEvents';
import ErrorIcon from '@mui/icons-material/Error';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MedalIcon from '@mui/icons-material/MilitaryTech';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { Box, Button, CardContent, Collapse, Grid, List, ListItem, ListItemText, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createCampaignSnapshotResult, getCampaignDetails, getCampaignPartialResult, getProductsList, getUserLeads } from '../../../api';
import { CustomBadge } from '../../../components/CustomBadge/CustomBadge';
import { CustomCard } from '../../../components/CustomCard/CustomCard';
import { CustomProgress } from '../../../components/CustomProgress/CustomProgress';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import { formatDate, getCampaignStatusColor, getLeadStatusColor } from '../../../utils';
import { BronzeIcon, BronzeRow, GoldIcon, GoldRow, SilverIcon, SilverRow, StyledCard, StyledTable, StyledTableContainer, StyledTableHeadCell } from './styles';

interface Lead {
    id: number;
    name: string;
    productId: number;
    status: string;
    planValue: string;
    moskitId: string;
  }
interface CampaignPartialResult {
    user_id: number;
    username: string;
    cpf: string;
    name: string;
    campaign_id: number;
    leads_total: number;
    leads_conv: number;
    ticket_avg: number | null;
    leads: Lead[];
  }

  interface CampaignRule {
    id: number;
    name: string;
    campaign_rules: {
      id: number;
      ruleId: number;
      campaignId: number;
      position: number;
      createdAt: string;
      updatedAt: string;
      rule_id: number;
      campaign_id: number;
    };
  }

  interface Campaign {
    campaign: {
      id: number;
      name: string;
      description: string;
      targetIndication: number;
      maxWinners: number;
      startsAt: string;
      expiresAt: string;
      createdAt: string;
      updatedAt: string;
      status: string;
      rules: CampaignRule[];
    };
    participants: number;
  }
  
  

export const CampaignDetails = () => {
    const navigate = useNavigate();

    const [openRows, setOpenRows] = React.useState<Record<number, boolean>>({});
    const [partialResults, setPartialResults] = useState<CampaignPartialResult[]>([]);
    const [campaignDetails, setCampaignDetails] = useState<Campaign>();
    const [products, setProducts] = useState<{ id: number; name: string }[]>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>(); 


    const { campaignId } = useParams();
    const session = sessionStorage.getItem('iv-crm');
    const sessionData = session && JSON.parse(session);
    const userId = sessionData && sessionData.id;
      
    useEffect(() => {
      const fetchPartialResult = async () => {
        setIsLoading(true);
        try {
          const response = await getCampaignPartialResult(Number(campaignId));
          if (response.res) {
            setPartialResults(response.data);
          } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true);
          }
        } catch (error) {
              setAlertMessage({message: 'Erro ao tentar carregar resultado da campanha', severity: 'error'});
              setShowAlert(true);
        } finally {
          setIsLoading(false);
        }
      };

      const fetchCampaignDetails = async () => {
        setIsLoading(true);
          try {
            const response = await getCampaignDetails(Number(campaignId));
            if (response.res) {
              setCampaignDetails(response.data);
            } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true);
            }
          } catch (error) {
              setAlertMessage({message: 'Erro ao tentar carregar detalhes da campanha', severity: 'error'});
              setShowAlert(true);
          } finally {
            setIsLoading(false);
          }
      };
    
      const fetchProducts = async () => {
        try {
          setIsLoading(true);
          const response = await getProductsList();
          if (response.res) {
            setProducts(response.data);
          } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true); 
          }
        } catch (error) {
          setAlertMessage({message: 'Erro ao tentar listar produtos', severity: 'error'});
          setShowAlert(true);
        } finally {
          setIsLoading(false); 
        }
      };

      fetchPartialResult();
      fetchCampaignDetails();
      fetchProducts();
    }, [campaignId]); 
      

      const getProductName = (productId: number) => {
        const product = products.find(p => p.id === productId);
        return product ? product.name : 'Produto Não Encontrado';
      };    

      const handleShowLeads = async (index: number) => {
        const isOpen = !openRows[index];
        setOpenRows({ ...openRows, [index]: isOpen });
    
        if (isOpen) {
          if (!partialResults[index].leads) {
            try {
              const response = await getUserLeads(partialResults[index].user_id);
              if (response.res) {
                const filteredLeads = response.data.filter((lead: { campaign_id: number; }) => lead.campaign_id === Number(campaignId));

                const updatedResults = [...partialResults];
                updatedResults[index].leads = filteredLeads;
                setPartialResults(updatedResults);
              } else {
                setAlertMessage({message: response.message, severity: 'error'});
                setShowAlert(true);
              }
            } catch (error) {
              setAlertMessage({message: 'Erro ao tentar carregar leads dos usuários', severity: 'error'});
              setShowAlert(true);
            }
          }
        }
    };

    const handleGenerateFinalResult = async () => {
      try {
        const response = await createCampaignSnapshotResult(Number(campaignId), userId);
        if (response.res) {
          setAlertMessage({message: response.message ? response.message : "Snapshot do resultado criado com sucesso!", severity: 'success'});
          setShowAlert(true); 
          setTimeout(() => {
            navigate('/campanhas');
          }, 1000);
      } else {
          setAlertMessage({message: response.message, severity: 'error'});
          setShowAlert(true); 
      }
      } catch (error) {
        setAlertMessage({message: 'Erro ao tentar criar snapshot do resultado', severity: 'error'});
        setShowAlert(true);
      }
    };

  return (
    <Box>
      {showAlert && (
        <CustomSnackbar
          open={showAlert}
          onClose={() => setShowAlert(false)}
          message={alertMessage?.message || 'Ocorreu um erro'}
          severity={alertMessage?.severity}
        />
      )}  

      {isLoading || campaignDetails === undefined ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh' }}>
              <CustomProgress />
          </Box>
      ) : (        
          <>
          
            {campaignDetails.campaign.status === 'Em análise' && (
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button 
                  variant="outlined"
                  onClick={handleGenerateFinalResult}
                  startIcon={<AddTaskIcon />}
                >
                  Salvar Resultado
                </Button>
              </Box>
            )}

              <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">{campaignDetails.campaign.name}</Typography>
                              <Typography variant="body1" sx={{py: '1rem'}} color="textSecondary">{campaignDetails.campaign.description}</Typography>
                              <Grid container spacing={1} mt=".5rem">
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Início</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.startsAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                      <Typography variant="subtitle2" color="primary.main" fontWeight="bold">Término</Typography>
                                      <Typography variant="body1" color="textSecondary">{formatDate(campaignDetails.campaign.expiresAt)}</Typography>
                                  </Grid>
                                  <Grid item xs={12} sm={4} 
                                    sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}
                                  >
                                      <CustomBadge color={getCampaignStatusColor(campaignDetails.campaign.status)} message={campaignDetails.campaign.status} />
                                  </Grid>
                              </Grid>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={6}  >
                      <StyledCard variant="outlined">
                          <CardContent>
                              <Grid container spacing={2} alignItems="center">
                                  <Grid item xs={12}>
                                      <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Critério de Desempate</Typography>
                                      <List>
                                          {campaignDetails.campaign.rules.map((rule, index) => (
                                              <ListItem key={rule.id}>
                                                  <ListItemText primary={`${index + 1}. ${rule.name}`} color="textSecondary"/>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </Grid>
                              </Grid>
                          </CardContent>

                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Total Participantes</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.participants}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Máx. de Vencedores</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.campaign.maxWinners}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                      <StyledCard variant="outlined">
                          <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                              <Typography component="h2" variant="h5" color="primary.main" fontWeight="bold">Min. de Indicações</Typography>
                              <Typography variant="body1" color="textSecondary" fontSize={'24px'} style={{ flexGrow: 1, alignSelf: 'flex-end' }}>{campaignDetails.campaign.targetIndication}</Typography>
                          </CardContent>
                      </StyledCard>
                  </Grid>

                  

              </Grid>

            <CustomCard title="Classificação Geral" icon={<Leaderboard />}>
              <StyledTableContainer>
                    <StyledTable>
                        <TableHead>
                            <TableRow>
                              <StyledTableHeadCell>Posição</StyledTableHeadCell>
                              <StyledTableHeadCell >Nome</StyledTableHeadCell>
                              <StyledTableHeadCell >Leads Cadastrados</StyledTableHeadCell>
                              <StyledTableHeadCell >Leads Convertidos</StyledTableHeadCell>
                              <StyledTableHeadCell >Ticket Médio</StyledTableHeadCell>
                              <StyledTableHeadCell></StyledTableHeadCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {partialResults.map((person, index) => {
                              let CustomTableRow;
                              const maxWinners = campaignDetails.campaign.maxWinners;
                              const targetIndication = campaignDetails.campaign.targetIndication;
                              const position = index + 1;


                              if (position <= maxWinners) {
                                if (index === 0) {
                                    CustomTableRow = GoldRow;
                                } else if (index === 1) {
                                    CustomTableRow = SilverRow;
                                } else if (index === 2) {
                                    CustomTableRow = BronzeRow;
                                } else {
                                    CustomTableRow = TableRow;
                                }
                            } else {
                                CustomTableRow = TableRow;
                            }

                                return (
                                    <React.Fragment key={person.user_id}>
                                        <CustomTableRow sx={{ height: '70px' }}>
                                            <TableCell >
                                                <Box display="flex" alignItems="center">
                                                {position <= maxWinners && index < 3 && person.leads_conv >= targetIndication ? (
                                                  <React.Fragment>
                                                      {index === 0 && <GoldIcon as={TrophyIcon} />}
                                                      {index === 1 && <SilverIcon as={MedalIcon} />}
                                                      {index === 2 && <BronzeIcon as={MedalIcon} />}
                                                  </React.Fragment>
                                              ) : (
                                                        <Box width={24} />
                                                    )}
                                                  <Box ml={2}>{ person.leads_conv >= targetIndication ? position + 'º' :  '' }</Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>{person.name}</TableCell>
                                            <TableCell>{person.leads_total}</TableCell>
                                            <TableCell>
                                              <Box display="flex" alignItems="center">
                                                  {person.leads_conv}
                                                  {person.leads_conv >= targetIndication ? 
                                                      <Tooltip title="Elegível"><CheckCircleIcon sx={{color: 'success.light', ml: 1}} /></Tooltip> : 
                                                      <Tooltip title="Não Elegível"><ErrorIcon sx={{color: 'error.light', ml: 1}}/></Tooltip>
                                                  }
                                              </Box>
                                          </TableCell>
                                          <TableCell>{person.ticket_avg ? `R$ ${person.ticket_avg.toFixed(2)}` : ''}</TableCell>
                                            
                                            <TableCell>
                                                <IconButton aria-label="expand row" size="small" onClick={() => handleShowLeads(index)}>
                                                    {openRows[index] ? <Tooltip title="Esconder Leads"><KeyboardArrowUpIcon /></Tooltip> : <Tooltip title="Mostrar Leads"><KeyboardArrowDownIcon /></Tooltip>}
                                                </IconButton>
                                            </TableCell>

                                        </CustomTableRow>
                                        <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                                                    <Box margin={1}>
                                                        <Typography variant="h6" gutterBottom component="div">
                                                            Leads
                                                        </Typography>
                                                        <StyledTable size="small" aria-label="leads">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <StyledTableHeadCell>Nome</StyledTableHeadCell>
                                                                    <StyledTableHeadCell>Produto</StyledTableHeadCell>
                                                                    <StyledTableHeadCell align='center'>Situação</StyledTableHeadCell>
                                                                    <StyledTableHeadCell>Valor Convertido</StyledTableHeadCell>
                                                                    <StyledTableHeadCell align='center'>Sincronizado</StyledTableHeadCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                              {person.leads && person.leads.length > 0 ? (
                                                                  person.leads.map((lead) => (
                                                                      <TableRow key={lead.id} sx={{ height: '50px' }}>
                                                                          <TableCell>{lead.name}</TableCell>
                                                                          <TableCell>{getProductName(lead.productId)}</TableCell>
                                                                          <TableCell align='center'>
                                                                            <CustomBadge color={getLeadStatusColor(lead.status)} message={lead.status} />
                                                                          </TableCell>
                                                                          <TableCell>{lead.planValue ? `R$ ${Number(lead.planValue).toFixed(2)}` : ''}</TableCell>
                                                                          <TableCell align='center'>{lead.moskitId ? <Tooltip title="Sincronizado"><SyncIcon sx={{color: 'success.light'}} /></Tooltip> : <Tooltip title="Não Sincronizado"><SyncProblemIcon sx={{color: 'error.light'}}/></Tooltip>}</TableCell>
                                                                      </TableRow>
                                                                  ))
                                                              ) : (
                                                                  <TableRow>
                                                                      <TableCell colSpan={5} align="center">
                                                                          Nenhum Lead Cadastrado
                                                                      </TableCell>
                                                                  </TableRow>
                                                              )}
                                                          </TableBody>
                                                        </StyledTable>
                                                    </Box>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </StyledTable>
              </StyledTableContainer>
            </CustomCard>
            </>
          )}
    </Box>
  );
};