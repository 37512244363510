import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

  export const FormColumn = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    flexWrap: 'nowrap',
  });
  
  export const BannerColumn = styled(Grid)(({ theme }) => ({
    color: '#fff',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  
  export const Banner = styled('div')(
    ({ bgImage }: { bgImage: string }) => ({
      backgroundImage: `url(${bgImage})`,
      transform: 'scaleX(-1)',
      margin: '16px',
      borderRadius: '12px',
      height: 'calc(100% - 2rem)',
      width: '100%',
    }),
  );