import { Dashboard } from '@mui/icons-material';
import CampaignIcon from '@mui/icons-material/Campaign';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo_sempre.png";
import { useDrawer } from "../../contexts/DrawerContext";
import { StyledDivider, StyledDrawer, StyledListItemButton } from "./styles";

export default function CustomDrawer() {
  const navigate = useNavigate();
  const location = useLocation();

  const { open, setOpen } = useDrawer();
  const [selectedItem, setSelectedItem] = useState("");

  const theme = useTheme();
  const isLargeEnough = useMediaQuery(theme.breakpoints.up('lg'));

  const items = useMemo(() => [
    {
      title: 'Início',
      icon: <Dashboard />,
      path: '/inicio',
      section: 'Início',
    },
    {
      title: 'Cadastro de Leads',
      icon: <GroupsIcon />,
      path: '/leads',
      section: 'Leads',
    },
    {
      title: 'Indique Sempre',
      icon: <LocalActivityIcon />,
      path: '/indique-sempre',
      section: 'Leads',
    },
    {
      title: 'Campanhas',
      icon: <CampaignIcon />,
      path: '/campanhas',
      section: 'Gerenciar',
      permission: 'ADMIN',
    },
    {
      title: 'Usuários',
      icon: <PeopleIcon />,
      path: '/usuarios',
      section: 'Gerenciar',
      permission: 'ADMIN',
    },
  ], []);

  useEffect(() => {
    const matchingItem = items.find((item) => location.pathname.startsWith(item.path));
    if (matchingItem) {
      setSelectedItem(matchingItem.title);
    } else {
      setSelectedItem("");
    }
  }, [items, location.pathname]);
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleItemClick = (item: string, path: string) => {
    setSelectedItem(item);
    navigate(path);
    if (!isLargeEnough) {
        setOpen(false);
    }
};

  const logOut = () => {
    sessionStorage.removeItem("iv-crm");
    navigate("/");
  };

  const checkPermission = (permission: string) => {
    const sessionObj = sessionStorage.getItem("iv-crm");
    if (sessionObj !== null && sessionObj !== '""') {
      const session = JSON.parse(sessionObj);
      if (session.roles.includes("ROLE_" + permission)) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <StyledDrawer
      variant={isLargeEnough ? 'permanent' : 'temporary'}
      open={isLargeEnough ? true : open}
      onClose={handleDrawerClose}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', my: '2em' }}>
          <img alt="logo" src={logo} style={{ width: "50%" }} />
        </Box>
        {!isLargeEnough && (
          <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        <StyledDivider />
  
        {['Início', 'Leads', 'Gerenciar'].map((section) => (
          (section !== 'Gerenciar' || items.some(item => item.section === 'Gerenciar' && (!item.permission || checkPermission(item.permission)))) && (
          <List key={section}>
            <ListSubheader>{section}</ListSubheader>
            {items
              .filter((item) => item.section === section && (!item.permission || checkPermission(item.permission)))
              .map((item) => (
                <StyledListItemButton
                  key={item.title}
                  onClick={() => handleItemClick(item.title, item.path)}
                  selected={selectedItem === item.title}
                >
                  <ListItemIcon>
                    {React.cloneElement(item.icon, {
                      sx: { color: selectedItem === item.title ? '#fff' : 'primary.main' },
                    })}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </StyledListItemButton>
              ))}
          </List>
          )
        ))}
  
        <Box sx={{ marginTop: 'auto' }}>
          <StyledDivider />
          <List>
            <StyledListItemButton key="Sair" onClick={logOut}>
              <ListItemIcon>
                <ExitToAppIcon sx={{ color: 'primary.main' }} />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </StyledListItemButton>
          </List>
        </Box>
      </Box>
    </StyledDrawer>
  );
}
