import { Badge } from "@mui/material";
import { styled } from "@mui/system";

export const StyledBadge = styled(Badge)(({ theme, color }) => {
  let backgroundColor, contrastTextColor;

  switch (color) {
    case 'error':
      backgroundColor = theme.palette.error.light;
      contrastTextColor = theme.palette.error.contrastText;
      break;
    case 'warning':
      backgroundColor = theme.palette.warning.light;
      contrastTextColor = theme.palette.warning.contrastText;
      break;
    case 'info':
      backgroundColor = theme.palette.info.light;
      contrastTextColor = theme.palette.info.contrastText;
      break;
    case 'success':
      backgroundColor = theme.palette.success.light;
      contrastTextColor = theme.palette.success.contrastText;
      break;
    default:
      backgroundColor = theme.palette.background.default;
      contrastTextColor = theme.palette.text.primary;
  }

  return {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px 8px',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: backgroundColor,
    color: contrastTextColor,
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  };
});
