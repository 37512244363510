import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, LinearProgress, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { signIn } from '../../../api';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import CpfMask from '../../../components/Masks/CpfMask';
import { removeMask } from '../../../utils';
import { StyledButton, StyledDivider, StyledForm, StyledLink } from './styles';

export default function Login() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();



  const validationSchema = yup.object({
    username: yup
      .string()
      .required('CPF é obrigatório'),
    password: yup
      .string()
      .min(8, 'Senha deve ter pelo menos 8 caracteres')
      .required('Senha é obrigatória'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      try {
        const response = await signIn(removeMask(values.username), values.password);
        sessionStorage.setItem("iv-crm", JSON.stringify(response.data));
        if (response.res) {
          navigate("/inicio");
        } else {
          setAlertMessage({message: response.message, severity: 'error'});
          setShowAlert(true);
        }
      } catch (error) {
        setAlertMessage({message: 'Erro ao tentar realizar login', severity: 'error'});
        setShowAlert(true);
      } 
    },
  });

  return (
    <Box>
      <Box sx={{pt: '2rem', px: '1rem', maxWidth: '480px', textAlign: 'left'}}>
        <Typography variant="h5" component="h1" fontWeight={'bold'} color={'#184981'}>Boas-vindas!</Typography>
        <Typography variant="subtitle1" component="h2">Insira seus dados para acessar sua conta</Typography>
      </Box>

      <StyledForm onSubmit={formik.handleSubmit} noValidate>      
        {showAlert && (
            <CustomSnackbar
            open={showAlert}
            onClose={() => setShowAlert(false)}
            message={alertMessage?.message || 'Ocorreu um erro'}
            severity={alertMessage?.severity}
          />
        )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="CPF"
            name="username"
            autoComplete="username"
            autoFocus
            value={formik.values.username}
            onChange={formik.handleChange}
            error={formik.touched.username && Boolean(formik.errors.username)}
            helperText={formik.touched.username && formik.errors.username}
            InputProps={{
              inputComponent: CpfMask as any,
            }}

          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ textAlign: 'right' }}>
            <StyledLink to="/esqueci-minha-senha" >
              Esqueceu a senha?
            </StyledLink>
          </Box>

        <StyledDivider />

        <StyledButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={showAlert}
          >
            Entrar
          </StyledButton>

          <Box sx={{ textAlign: 'right', mb: '1rem' }}>
            Não possui uma conta?
            <StyledLink to="/cadastro">
              Cadastre-se
            </StyledLink>
          </Box>
          {showAlert && (
            <LinearProgress sx={{ width: '100%', mt: '4px' }}/>
          )}
      </StyledForm>
    </Box>
  );
}
