import { Box, Typography } from '@mui/material';
import loading from '../../assets/images/loading.png';
import { LoadingContainer } from './styles';

export const CustomProgress = () => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column'}}>
      <LoadingContainer>
        <img src={loading} alt="Loading" />
      </LoadingContainer>
      <Typography fontWeight={'bold'} color={'primary.main'}>Carregando...</Typography>
    </Box>
  )
}

