import { Box } from '@mui/material';
import { keyframes, styled } from '@mui/system';

export const pulse = keyframes`
  0% {
    transform: scale(.5);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(.5);
  }
`;

export const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%', 
    width: '100%',
    paddingBottom: '1rem', 

    '& img': {
      animation: `${pulse} 2s infinite ease-in-out`
    }

});

