interface CompanyProduct { [key: string]: number; }
interface ProductInterface { [key:string]: CompanyProduct }

export const getProductIdFromCompany = (input_product: string, input_company: string) => {
    const helper: ProductInterface = {
        prever_master: {
            '341247': 15634,
        },
        prever_prime: {
            '341247': 15629,
        },
        corpos_imediato: {
            '341247': 15630,
        },
        corpos_futuro: {
            '341247': 15631,
        },
        restos_imediato: {
            '341247': 15632,
        },
        restos_futuro: {
            '341247': 15633,
        },
        modulo: {
            '341247': 88823,
        },
        jazigo_2: {
            '341247': 15641,
            '341244': 88829,
            '341246': 88829,
        },
        jazigo_3: {
            '341247': 15642,
        },
        jazigo_2_natal:{
            '341247': 15641,
        },
        jazigo_3_natal:{
        '341247': 15642,
        },
        jazigo_2_sfi:{
        '341246':279280,
        },
        jazigo_3_sfi:{
        '341246':279282,
        },
        jazigo_vip_caico:{
        '341244':279275

        },
        jazigo_standard_caico:{
        '341244':279279
        },
        pet_imediato: {
            '341247': 15628,
        },
        pet_futuro: {
            '341247': 15626,
        },
        pleno: {
            '341247': 15643,
            '341244': 15643,
            '341246': 15643,
            '341243': 15643,
            '341245': 15643,
        },
        mais: {
            '341247': 88824,
            '341244': 88824,
            '341246': 88824,
            '341243': 88824,
            '341245': 88824,
        },
        melhor: {
            '341247': 88825,
            '341244': 88825,
            '341246': 88825,
            '341243': 88824,
            '341245': 88824,
        },
        unico: {
            '341247': 15645,
            '341244': 88828,
            '341246': 33998,
        },
        sempre_do_seu_jeito:{
            '341245':271415,
            '341243':271415,
            '341246':271415,
            '341244':271415,
            '341247':271415
       }

    };

    const product: number = helper[input_product][input_company] || 0;

    return product;
}