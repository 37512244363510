import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import { sendVerificationCodeToEmail, sendVerificationCodeToPhone, signIn, signUp, verifyUser } from '../../../api';
import { CustomSnackbar } from '../../../components/CustomSnackbar/CustomSnackbar';
import CpfMask from '../../../components/Masks/CpfMask';
import { removeMask } from '../../../utils';
import { StyledButton, StyledDivider, StyledForm, StyledLink, StyledOtpInput } from './styles';

export const Register = () => {

  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [cpf, setCpf] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [email, setEmail] = useState('');
  const [registration, setRegistration] = useState('');
  const [validationCode, setValidationCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [method, setMethod] = useState<"phone" | "email" | ''>('email');
  const [receivedCode, setReceivedCode] = useState('');
  const [name, setName] = useState('');
  const [recno, setRecno] = useState('');
  const [branch, setBranch] = useState('');
  const [company, setCompany] = useState('');
  const [username, setUsername] = useState('');
  const [counter, setCounter] = useState(90);
  const [showResend, setShowResend] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetOTP, setResetOTP] = useState(0);
  const [hasRequestedCode, setHasRequestedCode] = useState(false);
  
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState<{ message: string; severity: "success" | "error" }>();
  const [isLoading, setIsLoading] = useState(false);




  useEffect(() => {
    if (counter && counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      setShowResend(true);
    }
  }, [counter]);

  const stepOneValidationSchema = yup.object().shape({
    cpf: yup
      .string()
      .required("CPF é obrigatório")
      .min(14, "CPF está incompleto"),
    date: yup
      .date()
      .required("Data de nascimento é obrigatória"),
  });

  const stepOneFormik = useFormik({
    initialValues: {
      cpf: '',
      date: '',
    },
    validationSchema: stepOneValidationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
          const response = await verifyUser(removeMask(values.cpf), values.date);
          if (response.res === true) {
              setUsername(response.data.cpf);
              setCpf(response.data.cpf);
              setEmail(response.data.email);
              setPhoneNumber(response.data.phoneNumber);
              setName(response.data.name);
              setRegistration(response.data.registration);
              setRecno(response.data.recno);
              setBranch(response.data.branch);
              setCompany(response.data.company);
              setDateOfBirth(response.data.dateOfBirth);

              setStep(step + 1);
          } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true);
          }
      } catch (error: any) {
          setAlertMessage({message: 'Erro ao verificar cadastro', severity: 'error'});
          setShowAlert(true);
      } finally {
          setIsLoading(false);
      }
    },
  });

  const stepThreeValidationSchema = yup.object().shape({
    password: yup
      .string()
      .required("Senha é obrigatória")
      .min(8, "Senha deve ter pelo menos 8 caracteres"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password')], "As senhas devem corresponder")
      .required("Confirmação de senha é obrigatória"),
      termsAccepted: yup
      .bool()
      .oneOf([true], 'Aceitar as políticas de privacidade é obrigatório')
    });

  const stepThreeFormik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      termsAccepted: false,
    },
    validationSchema: stepThreeValidationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
          const response = await signUp(username, values.password, email, name, dateOfBirth, phoneNumber, recno.toString(), registration, company, branch, cpf);
          if (response.res === true) {
            const signInResponse = await signIn(email, values.password); 
            sessionStorage.setItem("iv-crm", JSON.stringify(signInResponse.data));
            if (signInResponse.res) {
              setAlertMessage({message: response.message ? response.message : "Cadastro realizado com sucesso!", severity: 'success'});
              setShowAlert(true); 
              setTimeout(() => {
                navigate('/home');
              }, 3000);

            } else {
              setShowAlert(true);
              setAlertMessage({message: response.message, severity: 'success'});

            }
          } else {
              setAlertMessage({message: response.message, severity: 'error'});
              setShowAlert(true);
          }
      } catch (error) {
          setAlertMessage({message: 'Erro ao tentar realizar cadastro', severity: 'error'});
          setShowAlert(true);
      } finally {
          setIsLoading(false);
      }
    },
  }); 
  

  const handleValidationCode = async (methodToUse: string) => {
    setShowAlert(false);
    setIsLoading(true);
    try {
      let response;
      if (methodToUse === "email") {
        response = await sendVerificationCodeToEmail(email, name);
        if (response.res === true) {
          setReceivedCode(response.data.code);
          setCounter(90);
          setShowResend(false);
        } else {
          setShowAlert(true);
        }
      } else if (methodToUse === "phone") {
        response = await sendVerificationCodeToPhone(phoneNumber);

        if (response.res === true) {
          setReceivedCode(response.data.code);
          setCounter(90);
          setShowResend(false);
        } else {
          setShowAlert(true);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setValidationCode('');
      setResetOTP(reset => reset + 1);
    }
  };

  const handleVerifyCode = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(validationCode !== '') {
      if(validationCode.toLowerCase() === receivedCode.toLowerCase()) {
        setStep(step + 1);
      } else {
        setShowAlert(true);
      }
    }
    else {
      alert('validationCode VAZIO')
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      navigate("/");
    }
  };

  const formatEmail = (email: string) => {
    const [local, domain] = email.split("@");
    return (
      local.substring(0, 4) +
      "*".repeat(Math.max(0, local.length - 4)) +
      "@" +
      domain
    );
  }

  const handleTabChange = () => {
    setHasRequestedCode((currentHasRequestedCode) => {
      if (counter === 0) {
        return false;
      } else {
        return currentHasRequestedCode;
      }
    });
  };
  
  
  return (
    <Box>

      <Box sx={{pt: '2rem', px: '1rem', maxWidth: '480px', textAlign: 'left'}}>
        <Typography variant="h5" component="h1" fontWeight={'bold'} color={'#184981'}>Crie sua Conta</Typography>
      </Box>

      {step === 1 && (
        <StyledForm onSubmit={stepOneFormik.handleSubmit} noValidate>
          <Box>
              <Typography variant="subtitle1" gutterBottom fontWeight={'bold'} color={'#60A0AF'}>
              ETAPA 1 DE 3
              </Typography>
              <Typography variant="body1" gutterBottom>
              Precisamos validar se você é um cliente cadastrado, para isso informe os seguintes dados nos campos abaixo
              </Typography>
          </Box>
          
          {showAlert && (
            <CustomSnackbar
            open={showAlert}
            onClose={() => setShowAlert(false)}
            message={alertMessage?.message || 'Ocorreu um erro'}
            severity={alertMessage?.severity}
          />
        )}

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="cpf"
            label="CPF"
            name="cpf"
            autoComplete="off"
            value={stepOneFormik.values.cpf}
            onChange={stepOneFormik.handleChange}
            onBlur={stepOneFormik.handleBlur} 
            error={stepOneFormik.touched.cpf && Boolean(stepOneFormik.errors.cpf)}
            helperText={stepOneFormik.touched.cpf && stepOneFormik.errors.cpf}
            InputProps={{
              inputComponent: CpfMask as any,
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="date"
            type="date"
            id="date"
            autoComplete="off"
            value={stepOneFormik.values.date}
            onChange={stepOneFormik.handleChange}
            onBlur={stepOneFormik.handleBlur} 
            error={stepOneFormik.touched.date && Boolean(stepOneFormik.errors.date)}
            helperText={stepOneFormik.touched.date && stepOneFormik.errors.date}
          />

          <StyledDivider />

          <Grid container columnSpacing={2} mb={'1rem'}>
            <Grid item xs={6}>
              <StyledButton
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleBack}

                disabled={isLoading}
              >
                Voltar
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading}
              >
                Continuar
              </StyledButton>
            </Grid>
          </Grid>

          {isLoading && <LinearProgress />}
        </StyledForm>
      )}

      {step === 2 && (
        <StyledForm onSubmit={handleVerifyCode}>
          <Box>
            <Typography variant="subtitle1" gutterBottom fontWeight={'bold'} color={'#60A0AF'}>
              ETAPA 2 DE 3
            </Typography>

            {email || phoneNumber ? (
            <Tabs
              value={method}
              indicatorColor="primary"
              textColor="primary"
              onChange={(event, newValue) => {
                setMethod(newValue);
                handleTabChange();
              }}
              sx={{ 
                '& .MuiTab-wrapper': {
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                },
                '& .MuiTab-root': {
                  paddingLeft: 0,
                },
                mb: '1rem'
              }}
            >
              {email && <Tab label="Receber por Email" value="email" />}
              {phoneNumber && <Tab label="Receber por SMS" value="phone" />}
            </Tabs>
          ) : (
            <Typography variant="body1" gutterBottom align='center' color="error" >
              Seu cadastro atual não possui dados suficiente para prosseguir com o registro. Por favor, entre em contato com o Departamento Pessoal e solicite a atualização do email e do telefone.
            </Typography>
          )}

    {email || phoneNumber ? (
      
      
      <>
          <Typography variant="body1" gutterBottom>
            Solicite o envio do código de 6 dígitos para o {method === "email" ? "email" : "número"} abaixo e insira-o no recebido no campo indicado.
          </Typography>
          <Typography variant="body1" gutterBottom align='center' sx={{ color: 'primary.main', fontSize: '16px', fontWeight: 'bold',  my: '1rem' }}>
            {method === "email" ? formatEmail(email) : "*******" + phoneNumber.slice(-4)}
          </Typography>

          <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: '1rem'}}>
            <StyledOtpInput 
              key={resetOTP}
              type='text'
              fields={6}
              onChange={(code) => setValidationCode(code)}
              value={validationCode}
              name="codeInput"
              inputMode='numeric'
              isValid={!showAlert}
              inputStyleInvalid={{border:  '1px solid #d32f2f'}}
            />
          </Box>  

          {showAlert && (
              <Typography color="error" align="center" sx={{mb: '1rem'}} fontSize={'12px'}>
                Código Incorreto
              </Typography>
            )}

          {hasRequestedCode ? (

            <Typography variant="body1" gutterBottom align='center' sx={{mt: '1rem'}}>
              {showResend
                ? <span 
                    onClick={() => {
                      handleValidationCode(method);
                      setShowResend(false);
                      setValidationCode('');
                      setHasRequestedCode(true);
                    }} 
                    style={{ cursor: 'pointer', textDecoration: 'none', color: '#60A0AF', fontWeight: 'bold' }}
                  >
                    Reenviar Código
                  </span>
                : <span style={{ fontWeight: 'bold', color: 'lightgray' }}>
                    {`Reenvio disponível em: ${Math.floor(counter/60)}:${counter%60 < 10 ? '0' : ''}${counter%60}`}
                  </span>
              }
            </Typography>
          ) : (

            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                handleValidationCode(method);
                setHasRequestedCode(true);
              }}
              disabled={isLoading}
            >
              Enviar Código
            </StyledButton>
          )}
        </>
      ) : null}          
      </Box>
        <StyledDivider />

          <Grid container columnSpacing={2} mt={'1rem'}>
            <Grid item xs={6}>
              <StyledButton
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleBack}
                disabled={isLoading}
              >
                Voltar
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isLoading || validationCode.length !== 6}
                >
                Verificar
              </StyledButton>
            </Grid>
          </Grid>

          {isLoading && <LinearProgress />}
        </StyledForm>
      )}

      {step === 3 && (
        <StyledForm onSubmit={stepThreeFormik.handleSubmit} noValidate>
          <Box>
            <Typography variant="subtitle1" gutterBottom fontWeight={'bold'} color={'#60A0AF'}>
              ETAPA 3 DE 3
            </Typography>
            <Typography variant="body1" gutterBottom>
              Por favor, revise seus dados e defina sua senha:
            </Typography>
          </Box>

          {showAlert && (
            <CustomSnackbar
            open={showAlert}
            onClose={() => setShowAlert(false)}
            message={alertMessage?.message || 'Ocorreu um erro'}
            severity={alertMessage?.severity}
          />
        )}

          <TextField 
            value={name}
            label="Nome"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)'}}
            />


          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField 
                value={cpf}
                label="CPF"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)'}}
                  
              />
            </Grid>
            <Grid item xs={6}>
              <TextField 
                value={registration}
                label="Matrícula"
                fullWidth
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)'}}
              />
            </Grid>
          </Grid>

          <TextField 
            value={email}
            label="E-mail"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)'}}      
          />
          <TextField 
            value={phoneNumber}
            label="Telefone"
            fullWidth
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.09)'}}         
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="new-password"
            value={stepThreeFormik.values.password}
            onChange={stepThreeFormik.handleChange}
            onBlur={stepThreeFormik.handleBlur} 
            error={stepThreeFormik.touched.password && Boolean(stepThreeFormik.errors.password)}
            helperText={stepThreeFormik.touched.password && stepThreeFormik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirme a senha"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirmPassword"
            autoComplete="new-password"
            value={stepThreeFormik.values.confirmPassword}
            onChange={stepThreeFormik.handleChange}
            onBlur={stepThreeFormik.handleBlur} 
            error={stepThreeFormik.touched.confirmPassword && Boolean(stepThreeFormik.errors.confirmPassword)}
            helperText={stepThreeFormik.touched.confirmPassword && stepThreeFormik.errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={stepThreeFormik.values.termsAccepted}
                onChange={stepThreeFormik.handleChange}
                name="termsAccepted"
                color="primary"                
              />
            }
            label={
              <Typography variant="body1">
                Eu concordo com as 
                <StyledLink href="/politicas-de-privacidade" target="_blank">
                  Políticas de Privacidade
                </StyledLink>
              </Typography>
            }
          />

          {stepThreeFormik.touched.termsAccepted && stepThreeFormik.errors.termsAccepted ? (
            <Typography color="error" sx={{mb: '1rem', ml: '1rem'}} fontSize={'12px'}>
              {stepThreeFormik.errors.termsAccepted}
            </Typography>
          ) : null}

          <StyledDivider />

          <Grid container spacing={2} mb={'1rem'}>
            <Grid item xs={6}>
              <StyledButton
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleBack}
                disabled={isLoading}
              >
                Voltar
              </StyledButton>
            </Grid>
            <Grid item xs={6}>
              <StyledButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={showAlert}
              >
                Finalizar
              </StyledButton>
            </Grid>
          </Grid>

          {isLoading && <LinearProgress />}
        </StyledForm>
      )}
    </Box>            

  );
};
