import { Button, Dialog, styled } from "@mui/material";


export const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
  }));

  export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
      borderRadius: '12px', 
      padding: '1rem',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  }));
  