import { bluePrimary } from '../../assets/styles/colors';
import { CopyrightBox } from './styles';

export default function Copyright() {


   return (
    <CopyrightBox>
        <span>
          Copyright © {new Date().getFullYear()} SEMPRE - Indique Sempre. Todos os direitos reservados.
        </span>
        <a href="https://empresavila.com.br/politica-privacidade/" style={{ color: bluePrimary, fontWeight: 'bold', textDecoration: 'none' }}> Política de Privacidade</a>
    </CopyrightBox>
    );
}
