import api from "../axios";

// Components
import { Session } from '../../layouts/AuthenticatedLayout/AuthenticatedLayout';

//Interfaces

export const createCampaign = async (campaign: {
  name: string;
  startsAt: string;
  expiresAt: string;
  description: string | null;
  targetIndication: number | null;
  maxWinners: number | null;
  rules: { id: number; position: number }[];
}) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.post("/api/campaign", campaign, {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error: any) {
    // const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
    const errorMessage = error.response?.data?.message || "Ocorreu um erro ao criar a campanha";
    return { res: false, data: '', message: errorMessage };
  }
};

export const listCampaigns = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get("/api/campaign", {
      headers: {
        "x-access-token": token
      }
    });    
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getCampaignDetails = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/campaign/${id}`, {
      headers: {
        "x-access-token": token
      }
    });    
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const deleteCampaign = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.delete(`api/campaign/${id}`, {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const updateCampaign = async (id: number, campaign: {
  name: string;
  startsAt: string;
  expiresAt: string;
  description: string | null;
  targetIndication: number;
  maxWinners: number;
  rules: { id: number; position: number }[];
}) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.patch(`api/campaign/${id}`, campaign, {
      headers: {
        "x-access-token": token
      }
    });
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const listCampaignRules = async () => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get("/api/rule", {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getCampaignPartialResult = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/campaign/${id}/result/partial`, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const createCampaignSnapshotResult = async (campaignId: number, userId: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.post(`/api/campaign/${campaignId}/result`, {campaignId, userId}, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const listCampaignSnapshotResults = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }
  try {
    const response = await api.get(`/api/campaign/${id}/result`, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getCampaignSnapshotResult = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/campaign/result/${id}`, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const createCampaignFinalResult = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.patch(`/api/campaign/result/${id}/disclose`, {}, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getCampaignFinalResult = async (campaignId: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if (session !== null) {
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/campaign/${campaignId}/result/disclosed`, {
      headers: {
        "x-access-token": token
      }
    });

    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};

export const getCampaignFinalResultDetails = async (id: number) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
  if(session !== null){
    const sessionBody: Session = JSON.parse(session);
    token = sessionBody.accessToken;
  }

  try {
    const response = await api.get(`/api/campaign/result/${id}/detail`, {
      headers: {
        "x-access-token": token
      }
    });    
    return { res: true, data: response.data };
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || 'Erro desconhecido';
    return { res: false, data: '', message: errorMessage };
  }
};


  