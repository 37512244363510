import api from "../axios";

// Components
import { Session } from '../../layouts/AuthenticatedLayout/AuthenticatedLayout';

//Interfaces
import { User } from "../../pages/Admin/Users/Users";

export const createUser = async (user: {
  name: string;
  username: string;
  email: string;
  password: string;
  roles: Array<string>;
}) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
        if(session !== null){
            const sessionBody: Session = JSON.parse(session);
            token = sessionBody.accessToken;
          }
    try {
      const response = await api.post("api/auth/signup", user, {
        headers: {
          "x-access-token": token
        }
       });
      return { res: true, data: response.data };
    } catch (error: any) {
      // const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
      const errorMessage = error.response?.data?.message || "Ocorreu um erro ao criar o usuário";
      return { res: false, data: '', message: errorMessage };
    }
  };

  export const listUsers = async () => {
    const session = sessionStorage.getItem('iv-crm');
    let token = '';
    if (session !== null) {
      const sessionBody: Session = JSON.parse(session);
      token = sessionBody.accessToken;
    }
  
    try {
      const response = await api.get("/api/user/list", {
        headers: {
          "x-access-token": token
        }
      });
  
      return { res: true, data: response.data };
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || 'Erro desconhecido';
      return { res: false, data: '', message: errorMessage };
    }
  };
  
  
export const deleteUser = async (id: number | undefined) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
        if(session !== null){
            const sessionBody: Session = JSON.parse(session);
            token = sessionBody.accessToken;
          }
    try {
      const response = await api.delete(`api/user/${id}`, {
        headers: {
          "x-access-token": token
        }
        });
      return { res: true, data: response.data };
    } catch (error: any) {
      // const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
      const errorMessage = error.response?.data?.message || "Ocorreu um erro ao deletar o usuário";
      return { res: false, data: '', message: errorMessage };
    }
  };
  
export const updateUser = async (data: User) => {
  const session = sessionStorage.getItem('iv-crm');
  let token = '';
        if(session !== null){
            const sessionBody: Session = JSON.parse(session);
            token = sessionBody.accessToken;
          }
    try {
      const response = await api.put('api/user/update', data, {
        headers: {
          "x-access-token": token
        }
        });
      return { res: true, data: response.data };
    } catch (error: any) {
      // const errorMessage = error.response?.data?.errors[0]?.msg || 'Erro desconhecido';
      const errorMessage = error.response?.data?.message || "Ocorreu um erro ao atualizar o usuário";
      return { res: false, data: '', message: errorMessage };
    }
  };