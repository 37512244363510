// CampaignDetails/styles.ts

import { Box, Card, Container, Table, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';

import { SvgIcon } from '@mui/material';

export const GoldIcon = styled(SvgIcon)(({ theme }) => ({
    color: '#FFD700',
}));

export const SilverIcon = styled(SvgIcon)(({ theme }) => ({
    color: '#C0C0C0',
}));

export const BronzeIcon = styled(SvgIcon)(({ theme }) => ({
    color: '#CD7F32',
}));

export const UserRow = styled(TableRow)(({theme}) => ({
  // background: 'rgba(24, 73, 129, 1)',
  // borderBottom: '2px solid #184981',
}));

export const GoldRow = styled(TableRow)(({theme}) => ({
  background: 'linear-gradient(to left, rgba(255, 215, 0, 0.1), transparent)',
}));

export const SilverRow = styled(TableRow)(({theme}) => ({
  background: 'linear-gradient(to left, rgba(192, 192, 192, 0.2), transparent)',

}));

export const BronzeRow = styled(TableRow)(({theme}) => ({
  background: 'linear-gradient(to left, rgba(205, 127, 50, 0.1), transparent)',

}));



export const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 1.25rem 1.6875rem 0rem',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem',
    height: "100%",

}));

export const StyledContainer = styled(Container)(({ theme }) => ({
    overflowX: 'auto',
    width: 'calc(100vw - 3rem)',
    padding: '0',
    marginTop: '10rem',
}));

export const StyledTableContainer = styled(Box)(({ theme }) => ({
    borderRadius: '12px',
    overflow: 'auto',
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'nowrap'
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  paddingBottom: '0.5rem'
}));
