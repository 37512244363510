import { Box, Button, Container, Table, TableCell } from '@mui/material';
import { styled } from '@mui/system';


export const StyledContainer = styled(Container)(({ theme }) => ({
    overflowX: 'auto',
    overflowY: 'auto',  // Adicione esta linha
    width: 'calc(100vw - 3rem)',
    padding: '0',
  }));

export const StyledTableContainer = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  overflow: 'auto',
}));

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  fontSize: '10px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  paddingBottom: '0.5rem'
}));

export const StyledTable = styled(Table)(({ theme }) => ({
  whiteSpace: 'nowrap'
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
}));