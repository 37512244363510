import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import bannerRecoverPassword from '../src/assets/images/banner_1.png';
import bannerRegister from '../src/assets/images/banner_2.png';
import bannerLogin from '../src/assets/images/banner_3.png';
import './App.css';
import { bluePrimary, blueSecondary } from './assets/styles/colors';
import { DrawerProvider } from './contexts/DrawerContext';
import { AuthenticatedLayout } from './layouts/AuthenticatedLayout/AuthenticatedLayout';
import { GuestLayout } from './layouts/GuestLayout/GuestLayout';
import { CampaignDetails } from './pages/Admin/CampaignPartialResults/CampaignPartialResults';
import { CampaignSnapshotResults } from './pages/Admin/CampaignSnapshotResults/CampaignSnapshotResults';
import { Campaigns } from './pages/Admin/Campaigns/Campaigns';
import ListUsers from './pages/Admin/Users/Users';
import { ForgotPassword } from './pages/Auth/ForgotPassword/ForgotPassword';
import Login from './pages/Auth/Login/Login';
import { Register } from './pages/Auth/Register/Register';
import { ResetPassword } from './pages/Auth/ResetPassword/ResetPassword';
import { CCampaignFinalResults } from './pages/CCampaignFinalResults/CCampaignFinalResults';
import { CCampaignPartialResults } from './pages/CCampaignPartialResults/CCampaignPartialResults';
import { CCampaigns } from './pages/CCampaigns/CCampaigns';
import { Home } from './pages/Home/Home';
import Leads from './pages/Leads/Leads';
import { NotFound } from './pages/NotFound/NotFound';



function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: bluePrimary
      },
      secondary: {
        main: blueSecondary
      },
      background: {
        default: "rgb(240, 242, 245)",
      },

    },
  });
  return (
    <ThemeProvider theme={theme}>
      <DrawerProvider>
      <Router>
        <Routes>
          <Route path="/" element={<GuestLayout banner={bannerLogin}><Login /></GuestLayout>} />
          <Route path="/cadastro" element={<GuestLayout banner={bannerRegister}><Register /></GuestLayout>} />
          <Route path="/esqueci-minha-senha" element={<GuestLayout banner={bannerRecoverPassword}><ForgotPassword /></GuestLayout>} />
          <Route path="/redefinir-senha" element={<GuestLayout banner={bannerRecoverPassword}><ResetPassword /></GuestLayout>} />

          <Route path="/inicio" element={
            <AuthenticatedLayout 
              title="Início"
              breadcrumbs={[{ name: 'Início', href: '/inicio' }]}
            >
              <Home />
            </AuthenticatedLayout>} />

          <Route path="/leads" element={
            <AuthenticatedLayout 
              title="Cadastro de Leads" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Cadastro de Leads' }]}
            >
              <Leads />
            </AuthenticatedLayout>
          } />

          <Route path="/indique-sempre" element={
            <AuthenticatedLayout 
              title="Campanhas" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas' }]}
            >
              <CCampaigns />
            </AuthenticatedLayout>
          } />

          <Route path="/indique-sempre/:campaignId" element={
            <AuthenticatedLayout 
              title="Detalhes da Campanha" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas', href: '/indique-sempre' }, { name: 'Detalhes da Campanha' }]}
            >
              <CCampaignPartialResults />
            </AuthenticatedLayout>
          } />

          <Route path="/indique-sempre/:campaignId/resultados/:resultId" element={
            <AuthenticatedLayout 
              title="Resultado da Campanha" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas', href: '/indique-sempre' }, { name: 'Resultado da Campanha' }]}
            >
              <CCampaignFinalResults />
            </AuthenticatedLayout>
          } />

          <Route path="/campanhas" element={
            <AuthenticatedLayout 
              permission="ADMIN" 
              title="Campanhas" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas' }]}
            >
              <Campaigns />
            </AuthenticatedLayout>
          } />

          <Route path="/campanhas/:campaignId" element={
            <AuthenticatedLayout 
              permission="ADMIN" 
              title="Detalhes da Campanha" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas', href: '/campanhas' }, { name: 'Detalhes da Campanha' }]}
            >
              <CampaignDetails />
            </AuthenticatedLayout>
          } />

          <Route path="/campanhas/:campaignId/resultados/:resultId" element={
            <AuthenticatedLayout 
              permission="ADMIN" 
              title="Resultado da Campanha" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Campanhas', href: '/campanhas' }, { name: 'Resultado da Campanha' }]}
            >
              <CampaignSnapshotResults />
            </AuthenticatedLayout>
          } />

          <Route path="/usuarios" element={
            <AuthenticatedLayout 
              permission="ADMIN" 
              title="Usuários" 
              breadcrumbs={[{ name: 'Início', href: '/inicio' }, { name: 'Usuários' }]}
            >
              <ListUsers />
            </AuthenticatedLayout>
          } />

          <Route path="*" element={<NotFound />} />

        </Routes>
      </Router>
      </DrawerProvider>
    </ThemeProvider>
  );
}

export default App;
